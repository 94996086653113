import React from 'react';

import Types from './Types/Types';

const Banner = (props) => {
  return (
    <>
      <img src="images/banner02.jpg" style={{ width: '100%', }} />
          {/*
      <section className="banner-part">
        <div className="container">
          <div className="banner-content">
            <h1>Postavi se!<br />na svoje mesto!</h1>
            <h1><span style={{ color: '#ADEA18' }}>BESPLATNI OGLASI</span></h1>
            <h1><span style={{ color: '#ADEA18' }}>NOVA</span> digitalna platforma.</h1>
          </div>
        </div>
      </section>
          */}
      <Types />
    </>
  );
}

export default Banner;
