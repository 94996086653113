import React, { useEffect } from 'react';

import { withStore } from '../../../store';
import * as constants from '../../../constants';
import AdCard from '../../../components/AdCardSmall/AdCardSmall';

const MyAds = (props) => {
  useEffect(() => {
    props.ad.loadMyAds(props.auth.token);
  }, []);

  const ads = props.ad.myAds.map((el) => {
    let img = `${constants.PUBLIC_URL}/images/green.png`;

    if (el.images.length > 0) {
      img = `${constants.IMAGE_BASE_URL}/${el.images[0]}`;
    }

    return (
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
        <AdCard
          id={el.id}
          title={el.title}
          type={el.type.title}
          typeSlug={el.type.slug}
          category={el.category.title}
          subcategory={el.subcategory.title}
          priceType={constants.PRICE_TYPES.find(e => e.id === el.priceType).label}
          price={el.price}
          place={el.address && el.address.place}
          img={img} />
      </div>
    );
  });

  return (
    <section className="myads-part">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="header-filter">
            </div>
          </div>
        </div>
        <div className="row">
          {ads}
        </div>
      </div>
    </section>
  );
}

export default withStore(['auth', 'ad'])(MyAds);
