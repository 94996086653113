import React from 'react';
import { Link } from 'react-router-dom';

const slugToType = (slug) => {
  if (slug === 'usluge') {
    return 'sale';
  }
  if (slug === 'renta') {
    return 'rent';
  }
  if (slug === 'pokloni') {
    return 'booking';
  }
  if (slug === 'razmena') {
    return 'discount';
  }
  
  return '';
}

const SearchAd = (props) => {
  let priceType = 'dan';

  let place = null;
  if (props.place) {
    place = <span><i className="fas fa-map-marker-alt"></i>{props.place}</span>
  }

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ marginTop: 10, marginBottom: 10 }}>
    <Link to={`/ads/${props.id}`} style={{ width: '100%' }}>
      <div className="product-card standard">
        <div className="product-media">
          <div className="product-img">
            <img src={props.image} alt="product" />
          </div>
          <div className="cross-vertical-badge product-badge">
            {/*
            <i className="fas fa-clipboard-check"></i>
            <span>recommend</span>
            */}
          </div>
          <div className="product-type">
            {/*<span className={`flat-badge ${slugToType(props.typeSlug)}`}>{props.type}</span>*/}
          </div>
          <ul className="product-action">
            {/*
            <li className="view"><i className="fas fa-eye"></i><span>264</span></li>
            <li className="click"><i className="fas fa-mouse"></i><span>134</span></li>
            <li className="rating"><i className="fas fa-star"></i><span>4.5/7</span></li>
            */}
          </ul>
        </div>
        <div className="product-content">
          <ol className="breadcrumb product-category">
            <li><span className={`flat-badge ${slugToType(props.typeSlug)}`} style={{ marginRight: 10 }}>{props.type}</span></li>
            <li><i className="fas fa-tags"></i></li>
            <li className="breadcrumb-item"><a href="#">{props.category}</a></li>
            <li className="breadcrumb-item active" aria-current="page">{props.subcategory}</li>
          </ol>
          <h5 className="product-title">
            <Link to={`/ads/${props.id}`}>{props.title}</Link>
          </h5>
          <div className="product-meta">
            {place}
            {/*}<span><i className="fas fa-clock"></i>30 min ago</span>*/}
          </div>
          <div className="product-info">
            <h5 className="product-price">{props.price ? `${props.price} RSD` : ' ' }<span>/{props.priceType}</span></h5>
            <div className="product-btn">
              {/*
              <button type="button" title="Wishlist" className="far fa-heart"></button>
              */}
            </div>
          </div>
        </div>
      </div>
    </Link>
    </div>
  );
}

export default SearchAd;
