import React from 'react';
import { Link } from 'react-router-dom';

import { withStore } from '../../store';
import logo from '../../assets/img/logo_white.png';

const Footer = (props) => {
  return (
    <footer className="footer-part">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-content">
              <h3>Kontakt</h3>
              <ul className="footer-address">
                <li>
                  <i className="fas fa-envelope"></i>
                  <p>office@babezabe.com <span></span></p>
                </li>
                <li>
                  <i className="fas fa-phone-alt"></i>
                  <p>+381652044277 <span></span></p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-content">
              <h3>Linkovi</h3>
              <ul className="footer-widget">
                <li><Link to={props.auth.token ? '/profile/ads' : '/auth/login'}>Moji oglasi</Link></li>
                <li><Link to="/terms">Uslovi korišćenja</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-content">
              <h3>Informacije</h3>
              <ul className="footer-widget">
                <li><Link to="/about_us">O nama</Link></li>
                <li><a href="#">Kontakt</a></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-info">
            <a href="#"><img src={logo} alt="logo" /></a>
              <ul className="footer-count">
                <li>
                  <h5></h5>
                  <p></p>
                </li>
                <li>
                  <h5></h5>
                  <p></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div className="footer-end">
        <div className="container">
          <div className="footer-end-content">
            <p>All Copyrights Reserved © {new Date().getFullYear()}</p>
            <ul className="footer-social">
              <li><a href="https://www.facebook.com/people/Babe-%C5%BDabe/100077054397364/"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://www.instagram.com/babezabe_/"><i className="fab fa-instagram"></i></a></li>
              <li><a href="https://www.youtube.com/@babezabe"><i className="fab fa-youtube"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default withStore(['auth'])(Footer);
