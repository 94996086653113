import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { withStore } from '../../store';
import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import SearchSort from '../../components/SearchSort/SearchSort';
import SearchResults from '../../components/SearchResults/SearchResults';

const paramsToObject = (params) => {
  const res = {}

  const entries = params.entries();
  for (const [key, val] of entries) {
    res[key] = val;
  }

  return res;
}

const Search = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = paramsToObject(searchParams);

  useEffect(() => {
    const data = params;
    console.log(data);
    props.ad.searchAds(data);
  }, [searchParams]);

  return (
    <MainLayout>
      <PageBanner title="Pretraga" subtitle={params.type} small />
      <section className="inner-section ad-list-part">
        <div className="container">
          <div className="row content-reverse">
            <div className="col-lg-8 col-xl-9">
              <SearchSort />
              <SearchResults ads={props.ad.ads} />
            </div>
            <SearchFilter />
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default withStore(['ad'])(Search);
