import React from 'react';
import { Link } from 'react-router-dom';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

import * as constants from '../../../constants';

const slugToType = (slug) => {
  if (slug === 'usluge') {
    return 'sale';
  }
  if (slug === 'renta') {
    return 'rent';
  }
  if (slug === 'pokloni') {
    return 'booking';
  }
  if (slug === 'razmena') {
    return 'discount';
  }
  
  return '';
}

const AdDetails = (props) => {
  const images = props.images?.map((el) => {
    return {
      original: `${constants.IMAGE_BASE_URL}/${el}`,
      thumbnail: `${constants.IMAGE_BASE_URL}/${el}`,
    }
  });

  let link = null;
  if (props.editable) {
    link = (
      <>
        <Link to={`/profile/ads/${props.id}/edit`}>Izmeni</Link>
        &nbsp;
        &nbsp;
        &nbsp;
        <Link to="#" onClick={() => props.onAdRemove && props.onAdRemove(props.id)} style={{ color: 'red' }}>Obriši</Link>
      </>
    );
  }

  return (
    <div className="common-card">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ol className="breadcrumb ad-details-breadcrumb" style={{ flexGrow: 1 }}>
          <li><span className={`flat-badge ${slugToType(props.typeSlug)}`}>{props.type}</span></li>
          <li className="breadcrumb-item"><a href="#">{props.category}</a></li>
          <li className="breadcrumb-item active" aria-current="page">{props.subcategory}</li>
        </ol>
        {link}
      </div>
      {/*<h5 className="ad-details-address"></h5>*/}
      <h3 className="ad-details-title">{props.title}</h3>
      <div style={{ width: '50%', margin:'auto' }}>
        {props.images && props.images.length > 0 ? <ImageGallery items={images} thumbnailPosition="bottom" /> : null }
      </div>
    </div>
  );
}

export default AdDetails;
