import React from 'react';

import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';

const Help = (props) => {
  return (
    <MainLayout>
      <PageBanner title="Pomoć" small />
      <div class="setting-part">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="account-card alert fade show">
                <div class="account-title">
                  <h3>Uputstvo za korišćenje</h3>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>UPUTSTVO ZA PRIJAVU NA PLATFORMU</h5>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/tP9jOkKXRVE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>UPUTSTVO ZA KREIRANJE NOVOG OGLASA</h5>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/ZNGokclDqTA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </MainLayout>
  );
}

export default Help;
