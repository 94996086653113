import React from 'react';

import Autocomplete from "react-google-autocomplete";

import Spinner from './../Spinner/Spinne';
import * as constants from '../../constants';

const validate = (fields, field, value = null) => {
  let error = null;
  const validator = field.validator;
  const val = value ? value : field.value;

  for (let key in validator) {
    if (key === 'email') {
      const isValid = String(val)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (!isValid) {
        error = 'Vrednost ' + field.label + ' mora biti validna email adresa';
        break;
      }
    } else if (key === 'min') {
      if (val.length < validator.min) {
        error = 'Vrednost ' + field.label + ' mora biti diža od ' + validator.min + ' karaktera';
        break;
      }
    } else if (key === 'max') {
      if (val.length > validator.max) {
        error = 'Vrednost ' + field.label + ' mora biti diža od ' + validator.max + ' karaktera';
        break;
      }
    } else if (key === 'password') {
      const pass = fields.find(el => el.id === validator.password);
      if (val !== pass.value) {
        error = field.label + ' se ne podudara sa poljem ' + pass.label;
        break;
      }
    }
  }

  return error;
}

const Form = (props) => {
  const onChangeHandler = (event, el) => {
    if (!props.onChange) {
      return;
    }

    let value = event.target.value;
    if (el.type === 'checkbox') {
      value = event.target.checked;
    }

    let error = null;

    if (el.validator) {
      error = validate(props.fields, el, value);
    }

    props.onChange(el.id, value, error)
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (!props.onSubmit) {
      return;
    }

    props.onSubmit();
  }

  const components = props.fields.map((el) => {
    if (el.type === 'checkbox') {
      return (
        <div className="col-12" key={el.id}>
          <div className="form-group">
            <input id={el.id} type={el.type} className="fix-check" onChange={e => onChangeHandler(e, el)} />
            &nbsp;&nbsp;&nbsp;
            <label for="fix-check" className="form-check-text">{el.label}</label>
          </div>
        </div>
      )
    }

    if (el.type === 'address') {
      return (
        <div className="col-12" key={el.id}>
          <div className="form-group">
            <Autocomplete
              className="form-control"
              apiKey={constants.GOOGLE_MAPS_API_KEY}
              onPlaceSelected={place => {
              }} />
          {/*<label for="fix-check" className="form-check-text">{el.label}</label>*/}
          </div>
        </div>
      );
    }

    return (
      <div className="col-12" key={el.id}>
        <div className="form-group">
          <input id={el.id} type={el.type} className="form-control" placeholder={el.label} onChange={e => onChangeHandler(e, el)} />
          <small className="form-alert">{el.error}</small>
        </div>
      </div>
    );
  });

  let submit = (
    <button type="submit" className="btn btn-inline">
      {props.submitIcon && <i className={props.submitIcon}></i>}
      <span>{props.submit}</span>
    </button>
  );

  if (props.loading) {
    submit = (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="row">
        {components}
        <div class="col-12">
          <div className="form-group">
            {submit}
          </div>
        </div>
      </div>
    </form>
  );
}

export { validate };
export default Form;
