import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { withStore } from '../../../store';
import Form, { validate } from '../../../components/Form/Form';

const Register = (props) => {
  const [frm, setFrm] = useState([
    {
      id: 'name',
      type: 'text',
      label: 'Ime',
      value: '',
      validator: { min: 3 },
    },
    {
      id: 'surname',
      type: 'text',
      label: 'Prezime',
      value: '',
      validator: { min: 3 },
    },
    {
      id: 'email',
      type: 'email',
      label: 'Email',
      value: '',
      validator: { email: null },
    },
    {
      id: 'phone',
      type: 'text',
      label: 'Telefon',
      value: '',
      //validator: { min: 7 },
    },
    {
      id: 'password',
      type: 'password',
      label: 'Lozinka',
      value: '',
      validator: { min: 7 },
    },
    {
      id: 'password2',
      type: 'password',
      label: 'Ponovljena lozinka',
      value: '',
      validator: { password: 'password' },
    },
    {
      id: 'address',
      type: 'address',
      label: 'Adresa (opciono)',
      value: '',
    },
    {
      id: 'licence',
      type: 'checkbox',
      label: <>Prihvatam <Link to="/terms" target="_blank">uslove korišćenja</Link></>,
      value: false,
    },

  ]);

  const onChangeHandler = (id, val, err) => {
    const updatedFrm = [ ...frm ]

    const index = updatedFrm.findIndex(el => el.id === id);

    const updatedField = { ...updatedFrm[index] }
    updatedField.value = val;
    updatedField.error = err;

    updatedFrm[index] = updatedField;
    setFrm(updatedFrm);
  }

  const onSubmitHandler = () => {
    if (!frm.find(el => el.id === 'licence').value) {
      return;
    }

    props.auth.setLoading(true);
    let error = null;

    const data = {}

    for (const el of frm) {
      const err = validate(frm, el);

      if (err) {
        error = err;
        break;
      }

      data[el.id] = el.value;
    }

    if (error) {
      alert(error);
      props.auth.setLoading(false);
      return;
    }

    data.name = data.name + ' ' + data.surname;

    props.auth.register(data);
  }

  return (
    <div className="tab-pane active">
      <div className="user-form-title">
        <h2>Registracija</h2>
        <p></p>
      </div>

      <Form
        fields={frm}
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
        submit="Registruj se"
        submitIcon="fas fa-unlock"
        loading={props.auth.loading} />

      <div className="user-form-direction">
        <p>Već imaš nalog? <Link to="/auth">Prijavi se</Link>.</p>
      </div>
    </div>
  );
}

export default withStore(['auth'])(Register);
