import React, { useState } from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SideMenu from '../../components/SideMenu/SideMenu';

const MainLayout = (props) => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  return (
    <>
      <Header
        onShowSideMenu={() => setShowSideMenu(true)} />
      <SideMenu
        show={showSideMenu}
        onHideSideMenu={() => setShowSideMenu(false)} />
      {props.children}
      <Footer />
    </>
  );
}

export default MainLayout;
