import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { withStore } from '../../store';
import MainLayout from '../../hoc/Layouts/MainLayout';
import Banner from '../../components/Banner/Banner';
import AboutUs from '../../components/AboutUs/AboutUs';
import WhyWe from '../../components/WhyWe/WhyWe';
import CategoriesSlider from '../../components/CategoriesSlider/CategoriesSlider';

const HomePage = (props) => {
  useEffect(() => {
    props.category.load();
  }, []);

  const RentaDescription = () => {
    const [isFullText, setIsFullText] = useState(false);
    const text = '-    je  kategorija    gde   možete  postavljati  ili   tražiti   Oglase  koji  su   bazirani   na :   rentiranju   Nekretnina ,   Vozila ,   Poslovnih   ili   magacinskih  prostora... ';
    const fullText = '-    je  kategorija    gde   možete  postavljati  ili   tražiti   Oglase  koji  su   bazirani   na :   rentiranju   Nekretnina ,   Vozila ,   Poslovnih   ili   magacinskih  prostora ,  Sala  za  Venčanja ,  Rođendaonica ,  Građevinskih   mašina ,   Ozvučenja  i  rasvete ,  iznajmljivanja   Igrica   i  mnogih  drugh predmeta  ili  objekata .'
    
    let displayText = fullText;
    if (!isFullText) {
      displayText = (
        <>
          {text}
          <Link to="#" onClick={() => setIsFullText(true)}>Prikaži više</Link>
        </>
      );
    }

    return (
      <div>
        <Link to="/categories/renta" style={{ color: '#ADEA18' }}>RENTA</Link> {displayText}
      </div>
    );
  }

  const UslugeDescription = () => {
    const [isFullText, setIsFullText] = useState(false);
    const text = '-  su  Kategorija  gde   možete   postavljati   Oglase    iz  oblasti   različitih   uslužnih   delatnosti... ';
    const fullText = '-  su  Kategorija  gde   možete   postavljati   Oglase    iz  oblasti   različitih   uslužnih   delatnosti  :   Kućnih  majestora ,   Edukacije ,   Nege  i  lepote , Usluge    prevoza , Selidbi  ,   Čišćenja   i   odrzavanja ,   Turizma ,   Fotografa ,  Građevinskih  radnih   mašina...';

    let displayText = fullText;
    if (!isFullText) {
      displayText = (
        <>
          {text}
          <Link to="#" onClick={() => setIsFullText(true)}>Prikaži više</Link>
        </>
      );
    }

    return (
      <div>
        <Link to="/categories/usluge" style={{ color: '#ADEA18' }}>USLUGE</Link> {displayText}
      </div>
    );
  }

  const RazmenaDescription = () => {
    const [isFullText, setIsFullText] = useState(false);
    const text = '-  je  Kategorija  gde  možete  razmenjivati   baš   sve  što   vam   padne  na  pamet... ';
    const fullText = '-  je  Kategorija  gde  možete  razmenjivati   baš   sve  što   vam   padne  na  pamet .  Vozila ,  Tehniku ,  Nekretnine ,  Garderobu ,  mašine  ,  alate  i   mnoge   druge';

    let displayText = fullText;
    if (!isFullText) {
      displayText = (
        <>
          {text}
          <Link to="#" onClick={() => setIsFullText(true)}>Prikaži više</Link>
        </>
      );
    }

    return (
      <div>
        <Link to="/categories/razmena" style={{ color: '#ADEA18' }}>RAZMENA</Link> {displayText}
      </div>
    );
  }

  const PokloniDescription = () => {
    const [isFullText, setIsFullText] = useState(false);
    const text = '-  su   Kategorija   koja  će   vam   omogućiti  da  se  rešite   suvišnih  stvari .... ';
    const fullText = '-  su   Kategorija   koja  će   vam   omogućiti  da  se  rešite   suvišnih  stvari .  Nemojte  ih   baciti ,  Poklonite : Nameštaj ,    Garderobu ,   Knjige ,   Tehniku  kao  i  mnoge     druge   predmete   koji   vam  nisu  više  potrebni  a   nekome   mogu  poslužiti.. Možete  udomiti  ili  pokloniti  kućnog  ljubimca. Mislimo  jedni  o  drugima  i   podstaknimo  humanost .';

    let displayText = fullText;
    if (!isFullText) {
      displayText = (
        <>
          {text}
          <Link to="#" onClick={() => setIsFullText(true)}>Prikaži više</Link>
        </>
      );
    }
    
    return (
      <div>
        <Link to="/categories/pokloni" style={{ color: '#ADEA18' }}>POKLONI</Link> {displayText}
      </div>
    );
  }

  return (
    <>
      <MainLayout>
        <Banner />
        <section className="about-part">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div style={{ textAlign: 'center' }}>
                  <Link to={props.auth.isAuthenticated ? '/profile/new_ad' : '/auth'} className="btn btn-inline post-btn">
                    <i className="fas fa-plus-circle"></i>
                    <span>Postavi oglas</span>
                  </Link>
                  <Link to="/help" className="btn btn-inline post-btn" style={{ backgroundColor: 'white' }}>
                    <i className="fas fa-question-circle"></i>
                    <span>Pomoć</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CategoriesSlider
          title="Renta"
          description={<RentaDescription />}
          categories={props.category.categories.filter(el => el.type.slug === 'renta')} />
        <CategoriesSlider
          title="Usluge"
          description={<UslugeDescription />}
          categories={props.category.categories.filter(el => el.type.slug === 'usluge')} />
        <CategoriesSlider
          title="Razmena"
          description={<RazmenaDescription />}
          categories={props.category.categories.filter(el => el.type.slug === 'razmena')} />
        <CategoriesSlider
          title="Pokloni"
          description={<PokloniDescription />}
          categories={props.category.categories.filter(el => el.type.slug === 'pokloni')} />
        <AboutUs />
        <WhyWe />
        <br />
        <br />
      </MainLayout>
    </>
  );
}

export default withStore(['auth', 'category'])(HomePage);
