import React from 'react';
import { Link } from 'react-router-dom';

const PageBanner = (props) => {
  let classes = 'single-banner dashboard-banner';

  if (props.small) {
    classes = 'inner-section single-banner'
  }

  return (
    <section className={classes}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="single-content">
              <h2 style={{ fontFamily: 'BabeZabe Font'}}>{props.title}</h2>
              <ol className="breadcrumb" style={{ fontSize: 25 }}>
                <li className="breadcrumb-item"><Link to="/">Početna</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{props.subtitle ? props.subtitle : props.title}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageBanner;
