export const PRICE_TYPES = [
  {
    id: 1,
    label: 'Dogovor',
  },
  {
    id: 2,
    label: 'Fiksno',
  },
  {
    id: 3,
    label: 'Dan',
  },
  {
    id: 4,
    label: 'Sat',
  },
];

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCDanw3DSkfk7G1hMvtUn7ZDfeY8NH7TDc';

/*
export const BASE_URL = 'http://127.0.0.1:5000';
export const IMAGE_BASE_URL = 'http://127.0.0.1:5000/images';
export const PUBLIC_URL = 'http://localhost:3000';
*/

/*
export const BASE_URL = 'https://demoapi.babezabe.com';
export const IMAGE_BASE_URL = 'https://demoapi.babezabe.com/images';
export const PUBLIC_URL = 'https://demo.babezabe.com';
*/

export const BASE_URL = 'https://api.babezabe.com';
export const IMAGE_BASE_URL = 'https://api.babezabe.com/images';
export const PUBLIC_URL = 'https://babezabe.com';
