import React from 'react';

import * as constants from '../../constants';
import SearchAd from '../SearchAd/SearchAd';

const SearchResults = (props) => {
  const ads = props.ads.map((el) => {
    return (
      <SearchAd
        id={el.id}
        type={el.type.title}
        typeSlug={el.type.slug}
        title={el.title}
        price={el.price}
        priceType={constants.PRICE_TYPES.find(p => p.id === el.priceType)?.label}
        category={el.category.title}
        subcategory={el.subcategory.title}
        image={el.images.length > 0 ? `${constants.IMAGE_BASE_URL}/${el.images[0]}` : `${constants.PUBLIC_URL}/images/default_ad.png`}
        place={el.address && el.address.place} />
    );
  });

  return (
    <div className="row ad-standard">
      {ads}
    </div>
  );
}

export default SearchResults;
