import React from 'react';

const AdDescription = (props) => {
  return (
    <div className="common-card">
      <div className="card-header">
        <h5 className="card-title">Opis</h5>
      </div>
      <p className="ad-details-desc">{props.description}</p>
    </div>
  );
}

export default AdDescription;
