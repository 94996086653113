import React from 'react';

const WhyWe = (props) => {
  return (
    <section className="best-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-center-heading">
                            <h2>Zašto mi</h2>
                            {/*<p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>*/}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4 col-xl-4">
                        <div className="best-card">
                            <div className="best-icon">
                                <i className="fas fa-chart-line"></i>
                            </div>
                            <div className="best-content">
                                {/*}<h4>Deadline Reminders</h4>*/}
                                Povećajte   posećenost   vašeg  Oglasa  i  poboljšajte   vašu  poziciju  na   Google-u
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-4">
                        <div className="best-card">
                            <div className="best-icon">
                                <i className="fas fa-dollar-sign"></i>
                            </div>
                            <div className="best-content">
    {/*}<h4>easy get payment</h4>*/}
                                Uštedećete   vreme  i  novac  na  traženje  odgovarajućeg   oglasnog   portala ,  besplatinim   postavljanjem  Oglasa.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-4">
                        <div className="best-card">
                            <div className="best-icon">
                                <i className="fas fa-link"></i>
                            </div>
                            <div className="best-content">
    {/*}<h4>Verified Ad posting</h4>*/}
                                Tekstovi   sa  linkom  se  postavljaju  na  sajtove   sa  Domain  Rating-om  20 + i  organskim  saobraćajem  1000 +
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
}

export default WhyWe;
