import { runInAction, makeAutoObservable } from 'mobx';

import axios from '../axios-main';

class Ad {
  myAds = [];
  currentAd = {};
  ads = [];

  constructor() {
    makeAutoObservable(this);
  }

  searchAds = async (data) => {
    try {
      const response = await axios.get('/ads/search', { params: data });

      runInAction(() => {
        this.ads = response.data.ads;
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  insertAd = async (token, data, images) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }

    const body = new FormData();
    for (const key in data) {
      body.append(key, data[key]);
    }

    images.forEach((img) => {
      body.append('images', img);
    });

    try {
      const response = await axios.post('/ads', body, config);

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  updateAd = async (token, data, images) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }

    const body = new FormData();
    for (const key in data) {
      body.append(key, data[key]);
    }

    images.forEach((img) => {
      body.append('images', img);
    });

    try {
      const response = await axios.put(`/ads/${data.id}`, body, config);

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  removeAd = async (token, id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }

    try {
      const response = await axios.delete(`/ads/${id}`, config);

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  loadMyAds = async (token) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }

    try {
      const response = await axios.get('/ads/my', config);

      runInAction(() => {
        this.myAds = response.data.ads;
      });
    } catch (err) {
      console.log(err);
      runInAction(() => {
        this.myAds = [];
      });
    }
  }

  loadCurrentAd = async (id) => {
    try {
      const response = await axios.get(`/ads/${id}`);

      runInAction(() => {
        this.currentAd = response.data.ad;
      });
    } catch (err) {
      console.log(err);
      runInAction(() => {
        this.currentAd = {};
      });
    }
  }
}

export default Ad;
