import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import * as constants from '../../constants';

const MapEditor = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: constants.GOOGLE_MAPS_API_KEY,
  });

  const onMapClicked = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    props.onLocationUpdated(lat, lng);
  }

  const onClearClicked = () => {
    props.onLocationUpdated(0, 0);
  }

  let marker = null;

  if (props.lat && props.lng) {
    marker = (
      <Marker position={{lat: props.lat, lng: props.lng}} />
    );
  }

  return (
    <div className="common-card">
      <div className="card-header">
        <h5 className="card-title">Lokacija (Opciono)</h5>
      </div>
      <p>Kliknite na lokaiju na mapi ispod</p>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={{ lat: props.lat ? props.lat : 45.2460544, lng: props.lng ? props.lng : 19.8705152, }}
        zoom={14}
        onClick={onMapClicked}>
        {marker}
      </GoogleMap>
      <br />
      <button type="button" style={{ color: 'red' }} onClick={onClearClicked}>Poništi lokaciju</button>
    </div>
  );
}

export default MapEditor;
