import React from 'react';
import { Link } from 'react-router-dom';

import * as constants from '../../constants';

const CategoryCard = (props) => {
  const categories = props.subcategories?.map((el) => {
    return (
      <li key={el.id}><Link to={`/search?type=${el.type?.slug}&subcategory=${el.slug}`}><h6>{el.title}</h6><p></p></Link></li>
    );
  });

  const image = props.image ? `${constants.IMAGE_BASE_URL}/${props.image}` : `${constants.PUBLIC_URL}/images/green.png`;

  return (
    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
      <div className="category-card">
        <div className="category-head">
          <img src={image} alt="category" />
          <Link to={props.link} className="category-content">
            <h4>{props.title}</h4>
            <p></p>
          </Link>
        </div>
        <ul className="category-list">
          {categories}
        </ul>
      </div>
    </div>
  );
}

export default CategoryCard;
