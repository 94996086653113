import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { withStore } from '../../../store';
import MainLayout from '../../../hoc/Layouts/MainLayout';
import Banner from '../../../components/Banner/Banner';
import AboutUs from '../../../components/AboutUs/AboutUs';

const Logout = (props) => {
  useEffect(() => {
    props.auth.logout();
  }, []);

  if (!props.auth.isAuthenticated) {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <MainLayout>
      <Banner />
      <AboutUs />
      <br />
      <br />
    </MainLayout>
  );
}

export default withStore(['auth'])(Logout);
