import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { withStore } from '../../../store';
import Form from '../../../components/Form/Form';

const Login = (props) => {
  const [frm, setFrm] = useState([
    {
      id: 'email',
      type: 'text',
      label: 'Email',
      value: '',
    },
    {
      id: 'password',
      type: 'password',
      label: 'Lozinka',
      value: '',
    },
  ]);

  const onChangeHandler = (id, val, err) => {
    const updatedFrm = [ ...frm ]

    const index = updatedFrm.findIndex(el => el.id === id);

    const updatedField = { ...updatedFrm[index] }
    updatedField.value = val;
    updatedField.error = err;

    updatedFrm[index] = updatedField;
    setFrm(updatedFrm);
  }

  const onSubmitHandler = () => {
    props.auth.setLoading(true);

    const data = {}

    for (const el of frm) {
      data[el.id] = el.value;
    }

    props.auth.login(data.email, data.password);
  }

  return (
    <div className="tab-pane active">
      <div className="user-form-title">
        <h2>Dobrodišli! </h2>
        <p></p>
      </div>

      <Form
        fields={frm}
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
        submit="Prijavi se"
        submitIcon="fas fa-unlock"
        loading={props.auth.loading} />

      <div className="user-form-direction">
        <p>Nemaš nalog? <Link to="/auth/register">Registruj se</Link>.</p>
      </div>
    </div>
  );
}

export default withStore(['auth'])(Login);
