import React from 'react';

const SearchSort = (props) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="header-filter">
          <div className="filter-short">
            <label className="filter-label">Sortiraj po :</label>
            <select className="custom-select filter-select">
              <option selected="" value="0">Relevantno</option>
              <option value="1">Cena rastuće</option>
              <option value="2">Cena opadajuće</option>
            </select>
          </div>
          <div className="filter-action">
            {/*
            <a href="ad-column3.html" title="Three Column"><i className="fas fa-th"></i></a>
            <a href="ad-column2.html" title="Two Column"><i className="fas fa-th-large"></i></a>
            <a href="ad-column1.html" title="One Column" className="active"><i className="fas fa-th-list"></i></a>
            */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchSort;
