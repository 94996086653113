import React from 'react';
import { Link, Routes, Route, Navigate } from 'react-router-dom';

import { withStore } from '../../store';
import AuthBanner from '../../components/AuthBanner/AuthBanner';
import Login from './Login/Login.js';
import Register from './Register/Register.js';
import logo from '../../assets/img/logo.png';

const Auth = (props) => {
  if (props.auth.isAuthenticated) {
    return (
      <Navigate to='/' />
    );
  }

  return (
    <section className="user-form-part">
      <AuthBanner />

      <div className="user-form-category">
        <div className="user-form-header">
          <Link to="/"><img src={logo} alt="logo" /></Link>
          <Link to="/"><i className="fas fa-arrow-left"></i></Link>
        </div>
        <div className="user-form-category-btn">
          <ul className="nav nav-tabs">
            <li><Link to="/auth" className="nav-link active" data-toggle="tab">Prijava</Link></li>
            <li><Link to="/auth/register" className="nav-link" data-toggle="tab">Registracija</Link></li>
          </ul>

          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/*" element={<Login />} />
          </Routes>
        </div>
      </div>
    </section>
  );
}

export default withStore(['auth'])(Auth);
