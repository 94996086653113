import React from 'react';
import { Link } from 'react-router-dom';

import { withStore } from '../../store';
import * as constants from '../../constants';
import logo from '../../assets/img/logo.png';

const SideMenu = (props) => {
  const classes = [ 'sidebar-part '];

  if (props.show) {
    classes.push('active')
  }

  let user = (
    <div className="sidebar-profile">
      <Link to="/auth" className="sidebar-avatar"><img src={`${constants.PUBLIC_URL}/images/user.png`} alt="avatar" /></Link>
      <h4><Link to="/auth" className="sidebar-name">Pridruži se</Link></h4>
      <Link to="/auth" className="btn btn-inline sidebar-post">
        <i className="fas fa-plus-circle"></i>
        <span>post your ad</span>
      </Link>
    </div>
  );

  let menu = null;

  if (props.auth.isAuthenticated) {
    user = (
      <div className="sidebar-profile">
        <Link to="/profile" className="sidebar-avatar"><img src={`${constants.PUBLIC_URL}/images/user.png`} alt="avatar" /></Link>
        <h4><Link to="/profile" className="sidebar-name">{props.auth.user.name}</Link></h4>
        <Link to="/profile/new_ad" className="btn btn-inline sidebar-post">
          <i className="fas fa-plus-circle"></i>
          <span>Novi Oglas</span>
        </Link>
      </div>
    );

    menu = (
      <div className="sidebar-menu">
        <div className="tab-pane active" id="main-menu">
          <ul className="navbar-list">
            <li className="navbar-item"><Link className="navbar-link" to="/profile/ads">Moji oglasi</Link></li>
            <li className="navbar-item"><Link className="navbar-link" to="/profile/settings">Izmena podataka</Link></li>
            <li className="navbar-item"><Link className="navbar-link" to="/auth/logout">Logout</Link></li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <aside className={classes.join(' ')}>
      <div className="sidebar-body">
        <div className="sidebar-header">
          <Link to="/" className="sidebar-logo"><img src={logo} alt="logo" /></Link>
          <button className="sidebar-cross" onClick={props.onHideSideMenu}><i className="fas fa-times"></i></button>
        </div>
        <div className="sidebar-content">
          {user}
          {menu}
        </div>
      </div>
    </aside>
  );
}

export default withStore(['auth'])(SideMenu);
