import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withStore } from '../../store';
import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';
import CategoryCard from '../../components/CategoryCard/CategoryCard';

const slugToTitle = (slug) => {
  if (slug === 'renta') {
    return 'Renta';
  }
  if (slug === 'usluge') {
    return 'Usluge';
  }
  if (slug === 'razmena') {
    return 'Razmena';
  }
  if (slug === 'pokloni') {
    return 'Pokloni';
  }

  return 'Kategorije';
}

const Categories = (props) => {
  const { slug } = useParams();

  useEffect(() => {
    props.category.load();
  }, []);

  const categories = props.category.categories.filter(el => el.type.slug === slug).map((el) => {
    return (
      <CategoryCard
        key={el.id}
        title={el.title}
        image={el.image}
        link={`/search?type=${el.type?.slug}&category=${el.slug}`}
        subcategories={el.subcategories} />
    );
  });

  return (
    <MainLayout>
      <PageBanner title={slugToTitle(slug)} small />
      <div className="inner-section category-part">
        <div className="container">
          <div className="row">
            {categories}
          </div>
        </div>
      </div>
      <br />
      <br />
    </MainLayout>
  );
}

export default withStore(['category'])(Categories);
