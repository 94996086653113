import React from 'react';

import * as constants from '../../constants';
import Menu from './Menu/Menu';

const ProfileHeader = (props) => {
  return (
    <section className="dash-header-part">
      <div className="container">
        <div className="dash-header-card">
          <div className="row">
            <div className="col-lg-5">
              <div className="dash-header-left">
                <div className="dash-avatar">
                  <a href="#"><img src={`${constants.PUBLIC_URL}/images/user.png`} alt="avatar" /></a>
                </div>
                <div className="dash-intro">
                  <h4><a href="#">{props.name}</a></h4>
                  <h5></h5>
                  <ul className="dash-meta">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <span>{props.phone}</span>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <span>{props.email}</span>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="dash-header-right">
                {/*
                <div className="dash-focus dash-list">
                  <h2>2433</h2>
                  <p>listing ads</p>
                </div>
                <div className="dash-focus dash-book">
                  <h2>2433</h2>
                  <p>total follower</p>
                </div>
                <div className="dash-focus dash-rev">
                  <h2>2433</h2>
                  <p>total review</p>
                </div>
                */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Menu />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileHeader;
