import React from 'react';
import { GoogleMap, Marker, useJsApiLoader  } from '@react-google-maps/api';

import * as constants from '../../../constants';

const AdMap = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: constants.GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return <></>;
  }

  return (
    <div className="common-card">
      <div className="card-header">
        <h5 className="card-title">Lokacija</h5>
      </div>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={{ lat: props.lat, lng: props.lng, }}
        zoom={14}>
        <Marker position={{lat: props.lat, lng: props.lng}} />
      </GoogleMap>
    </div>
  );
}

export default AdMap;
