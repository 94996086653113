import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import { withStore } from '../../store';

const paramsToObject = (params) => {
  const res = {}

  const entries = params.entries();
  for (const [key, val] of entries) {
    res[key] = val;
  }

  return res;
}

const objectToURL = (params) => {
  let result =  '/search?';

  let index = 0;
  for (const [key, value] of Object.entries(params)) {
    if (index > 0) {
      result += '&';
    }
    result += key + '=' + value;
    index++;
  }

  return result;
}


const SearchFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [searchObj, setSearchObj] = useState({})

  useEffect(() => {
    props.category.load();
  }, []);

  useEffect(() => {
    const obj = paramsToObject(searchParams);
    setSearchObj(obj);
  }, [searchParams]);

  const filterByType = (type) => {
    const obj = { ...searchObj }
    obj.type = type;
    obj.category = null;
    obj.subcategory = null;
    navigate(objectToURL(obj));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }

  const filterByCategory = (category) => {
    const obj = { ...searchObj }
    //obj.type = type;
    obj.category = category;
    obj.subcategory = null;
    navigate(objectToURL(obj));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }

  const filterBysubcategory = (subcategory) => {
    const obj = { ...searchObj }
    //obj.type = type;
    //obj.category = null;
    obj.subcategory = subcategory;
    navigate(objectToURL(obj));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }

  const categories = props.category.categories.filter(el => el.type.slug === searchObj.type).map((el) => {
    let sub = null;
    let subs = null;
    if (el.slug === searchObj.category) {
      subs = el.subcategories.map((e) => {
        return (
          <li><a href="javascript:void()" onClick={event => filterBysubcategory(e.slug)}>{e.title}</a></li>
        );
      });

      sub = (
        <ul className="product-widget-dropdown" style={{ display: 'block' }}>
          {subs}
        </ul>
      );
    }

    return (
      <ul className="product-widget-list product-widgetscroll">
        <li className="product-widget-dropitem">
          <button type="button" className="product-widget-link" onClick={event => { filterByCategory(el.slug); }}>
            <i className="fas fa-tags"></i>
            {el.title} {/*(5)*/}
          </button>
          {sub}
        </li>
      </ul>
    );
  });

  let topSubcategories = null;
  if (searchObj.category) {
    const tmpCat = props.category.categories.find(el => el.slug === searchObj.category);
    const tmp = tmpCat?.subcategories?.map(el => {
      return (
        <li className="product-widget-dropitem">
          <button type="button" onClick={event => { filterBysubcategory(el.slug); }} style={{ textAlign: 'left' }}>
            {el.title} {/*(5)*/}
          </button>
        </li>
      )
    });
    topSubcategories = (
      <div className="product-widget">
        <ul className="product-widget-list product-widgetscroll">
          <li className="product-widget-dropitem">
            <button type="button" className="product-widget-link" onClick={event => { filterByCategory(tmpCat.slug); }} style={{ textTransform: 'uppercase' }}>
              <i className="fas fa-tags"></i>
              {tmpCat.title} {/*(5)*/}
            </button>
            <ul className="product-widget-dropdown" style={{ height: 300, overflow: 'auto', display: 'block' }}>
              {tmp}
            </ul>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="col-lg-4 col-xl-3">
      <div className="row">
        <div className="col-md-6 col-lg-12">
          {topSubcategories}
          <div className="product-widget">
            <h6 className="product-widget-title">Pretraga to tipu</h6>
            <form className="product-widget-form">
              <ul className="product-widget-list">
                <li className="product-widget-item">
                  <div className="product-widget-checkbox"><input type="checkbox" id="chcek2" checked={searchObj.type === 'renta'} onChange={event => filterByType('renta')} /></div>
                  <label className="product-widget-label" for="chcek2">
                    <span className="product-widget-type rent">renta</span>
                    {/*}<span className="product-widget-number">(15)</span>*/}
                  </label>
                </li>
                <li className="product-widget-item">
                  <div className="product-widget-checkbox">
                    <input type="checkbox" id="chcek1" checked={searchObj.type === 'usluge'} onChange={event => filterByType('usluge')} />
                  </div>
                  <label className="product-widget-label" for="chcek1">
                    <span className="product-widget-type sale">usluge</span>
                    {/*}<span className="product-widget-number">(15)</span>*/}
                  </label>
                </li>
                <li className="product-widget-item">
                  <div className="product-widget-checkbox"><input type="checkbox" id="chcek3" checked={searchObj.type === 'razmena'} onChange={event => filterByType('razmena')} /></div>
                  <label className="product-widget-label" for="chcek3">
                    <span className="product-widget-type discount">razmena</span>
                    {/*}<span className="product-widget-number">(15)</span>*/}
                  </label>
                </li>
                <li className="product-widget-item">
                  <div className="product-widget-checkbox"><input type="checkbox" id="chcek3" checked={searchObj.type === 'pokloni'} onChange={event => filterByType('pokloni')} /></div>
                  <label className="product-widget-label" for="chcek3">
                    <span className="product-widget-type booking">poklon</span>
                    {/*}<span className="product-widget-number">(15)</span>*/}
                  </label>
                </li>
              </ul>
              {/*
              <button type="button" className="product-widget-btn" onClick={() => filterByType(null)}>
                <i className="fas fa-broom"></i>
                <span>Prikaži Sve</span>
              </button>
              */}
            </form>
          </div>
        </div>
          {/*
          <div className="col-md-6 col-lg-12">
            <div className="product-widget">
              <h6 className="product-widget-title">Pretraga po oceni</h6>
              <form className="product-widget-form">
                <ul className="product-widget-list">
                  <li className="product-widget-item">
                    <div className="product-widget-checkbox">
                      <input type="checkbox" id="chcek4" />
                    </div>
                    <label className="product-widget-label" for="chcek4">
                      <span className="product-widget-star">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </span>
                      <span className="product-widget-number">(45)</span>
                    </label>
                  </li>
                  <li className="product-widget-item">
                    <div className="product-widget-checkbox">
                      <input type="checkbox" id="chcek5" />
                    </div>
                    <label className="product-widget-label" for="chcek5">
                      <span className="product-widget-star">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                      </span>
                      <span className="product-widget-number">(55)</span>
                    </label>
                  </li>
                  <li className="product-widget-item">
                    <div className="product-widget-checkbox">
                      <input type="checkbox" id="chcek6" />
                    </div>
                      <label className="product-widget-label" for="chcek6">
                        <span className="product-widget-star">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                        </span>
                        <span className="product-widget-number">(65)</span>
                      </label>
                    </li>
                    <li className="product-widget-item">
                      <div className="product-widget-checkbox">
                        <input type="checkbox" id="chcek7" />
                      </div>
                      <label className="product-widget-label" for="chcek7">
                        <span className="product-widget-star">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                        </span>
                        <span className="product-widget-number">(75)</span>
                      </label>
                    </li>
                    <li className="product-widget-item">
                      <div className="product-widget-checkbox">
                        <input type="checkbox" id="chcek8" />
                      </div>
                      <label className="product-widget-label" for="chcek8">
                        <span className="product-widget-star">
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                        </span>
                        <span className="product-widget-number">(85)</span>
                      </label>
                    </li>
                  </ul>
                  <button type="submit" className="product-widget-btn">
                    <i className="fas fa-broom"></i>
                    <span>Prikaži sve</span>
                  </button>
                </form>
              </div>
            </div>
            */}
    {/*
            <div className="col-md-6 col-lg-12">
              <div className="product-widget">
                <h6 className="product-widget-title">Pretraga po gradu</h6>
                <form className="product-widget-form">
                  <div className="product-widget-search">
                    {/*<input type="text" placeholder="Pretraga" />*}
                  </div>
                  <ul className="product-widget-list product-widget-scroll">
                    <li className="product-widget-item">
                      <div className="product-widget-checkbox">
                        <input type="checkbox" id="chcek9" />
                      </div>
                      <label className="product-widget-label" for="chcek9">
                        <span className="product-widget-text">Beograd</span>
                        {/*<span className="product-widget-number">(1)</span>*}
                      </label>
                    </li>
                    <li className="product-widget-item">
                      <div className="product-widget-checkbox">
                        <input type="checkbox" id="chcek10" />
                      </div>
                      <label className="product-widget-label" for="chcek10">
                        <span className="product-widget-text">Novi Sad</span>
                        {/*<span className="product-widget-number">(1)</span>*}
                      </label>
                    </li>
                    <li className="product-widget-item">
                      <div className="product-widget-checkbox">
                        <input type="checkbox" id="chcek11" />
                      </div>
                      <label className="product-widget-label" for="chcek11">
                        <span className="product-widget-text">Niš</span>
                        {/*<span className="product-widget-number">(1)</span>*}
                      </label>
                    </li>
                    <button type="submit" className="product-widget-btn">
                      <i className="fas fa-broom"></i>
                      <span>Prikaži sve</span>
                    </button>  
                  </ul>
                </form>
              </div>
            </div>
    */}
            {/*
            <div className="col-md-6 col-lg-12">
              <div className="product-widget">
                <h6 className="product-widget-title">Pretraga po kategoriji</h6>
                <form className="product-widget-form">
                */}
                  {/*
                  <div className="product-widget-search">
                    <input type="text" placeholder="search" />
                  </div>
                  */}
                  {/*categories*/}
                  {/*
                  <button type="button" className="product-widget-btn" onClick={() => { filterByCategory(null); }}>
                    <i className="fas fa-broom"></i>
                    <span>Prikaži sve</span>
                  </button>  
                </form>
              </div>
            </div>
            */}
            <div className="col-md-6 col-lg-12">
              <div className="product-widget">
                <h6 className="product-widget-title">Pretraga po ceni</h6>
                <form className="product-widget-form">
                  <div className="product-widget-group">
                    <input type="text" placeholder="min - 00" />
                    <input type="text" placeholder="max - 1M" />
                  </div>
                  <button type="button" className="product-widget-btn">
                    <i className="fas fa-search"></i>
                    <span>pretraga</span>
                  </button>
                </form>
              </div> 
            </div>
          </div>
        </div>
  );
}

export default withStore(['category'])(SearchFilter);
