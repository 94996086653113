import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { confirm } from "react-confirm-box";

import { withStore } from '../../store';
import * as constants from '../../constants';
import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';
import AdHeader from '../../components/Ad/AdHeader/AdHeader';
import AdDetails from '../../components/Ad/AdDetails/AdDetails';
import AdDescription from '../../components/Ad/AdDescription/AdDescription';
import AdReview from '../../components/Ad/AdReview/AdReview';
import AdMap from '../../components/Ad/AdMap/AdMap';

const Ad = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    props.ad.loadCurrentAd(id);
  }, [id]);

  const showPhoneHandler = () => {
  }

  const removeAd = async () => {
    const result = await confirm('Da li ste sigurni da želite da obrišete oglas?', {
      labels: {
        confirmable: 'Da',
        cancellable: 'Ne',
      },
    });
    if (!result) {
      return;
    }

    await props.ad.removeAd(props.auth.token, id);
    navigate('/profile/ads');
  }

  return (
    <MainLayout>
      <PageBanner title="Oglas" />
      <section className="inner-section ad-details-part" style={{ padding: 25 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <AdHeader
                price={props.ad.currentAd.price}
                priceType={constants.PRICE_TYPES.find(el => el.id === props.ad.currentAd.priceType)?.label}
                phone={props.ad.currentAd.user?.phone}
                onShowPhone={showPhoneHandler} />
              <AdDetails
                id={props.ad.currentAd.id}
                title={props.ad.currentAd.title}
                type={props.ad.currentAd.type?.title}
                typeSlug={props.ad.currentAd.type?.slug}
                category={props.ad.currentAd.category?.title}
                subcategory={props.ad.currentAd.subcategory?.title}
                images={props.ad.currentAd.images}
                editable={props.auth.user && props.auth.user?.id === props.ad.currentAd.user?.id}
                onAdRemove={removeAd} />
              <AdDescription
                description={props.ad.currentAd.description} />
                {/*}<AdReview />*/}
              {props.ad.currentAd.address && props.ad.currentAd.address.lat && props.ad.currentAd.address.lng ? <AdMap lat={props.ad.currentAd.address.lat} lng={props.ad.currentAd.address.lng} /> : null}
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </MainLayout>
  );
}

export default withStore(['auth', 'ad'])(Ad);
