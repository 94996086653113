import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import { withStore } from '../../store';
import * as constants from '../../constants';
import Notifications from './Notifications/Notifications';
import logo from '../../assets/img/logo.png';

const paramsToObject = (params) => {
  const res = {}

  const entries = params.entries();
  for (const [key, val] of entries) {
    res[key] = val;
  }

  return res;
}

const objectToURL = (params) => {
  let result =  '/search?';

  let index = 0;
  for (const [key, value] of Object.entries(params)) {
    if (index > 0) {
      result += '&';
    }
    result += key + '=' + value;
    index++;
  }

  return result;
}


const Header = (props) => {
  const [search, setSearch] = useState('');
  const [isSearchShow, setIsSearchShow] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [searchObj, setSearchObj] = useState({})

  useEffect(() => {
    let keywords = searchParams.get('keywords');
    if (!keywords) keywords = '';
    setSearch(keywords);

    const obj = paramsToObject(searchParams);
    setSearchObj(obj);
  }, [searchParams]);

  const searchSubmit = (event) => {
    event.preventDefault();
    const data = {
      ...searchObj,
      keywords: search,
    }
    console.log(data, objectToURL(data));
    navigate(objectToURL(data));
  }

  const showSearch = () => {
  }

  let user = (
    <Link to="/auth" className="header-widget header-user">
      <img src={`${constants.PUBLIC_URL}/images/user.png`} alt="user" />
      <span>Pridruži se</span>
    </Link>
  );

  let newAdLink = '/auth';

  if (props.auth.isAuthenticated) {
    user = (
      <Link to="/profile/ads" className="header-widget header-user">
        <img src={`${constants.PUBLIC_URL}/images/user.png`} alt="user" />
        <span>{props.auth.user.name}</span>
      </Link>
    );

    newAdLink = '/profile/new_ad';
  }

  return (
    <header className="header-part">
      <div className="container">
        <div className="header-content">
          <div className="header-left">
            <button type="button" className="header-widget sidebar-btn" onClick={props.onShowSideMenu}>
              <i className="fas fa-align-left"></i>
            </button>

            <Link to="/" className="header-logo">
              <img src={logo} alt="logo" />
            </Link>
            {user}
            <button type="button" className="header-widget search-btn" onClick={showSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
          <form className="header-form" onSubmit={searchSubmit}>
            <div className="header-search">
              <button type="submit" title="Search Submit ">
                <i className="fas fa-search"></i>
              </button>
              <input type="text" placeholder="Pretraga" value={search} onChange={(event) => setSearch(event.target.value)} />
              {/*
              <button type="button" title="Search Option" className="option-btn">
                <i className="fas fa-sliders-h"></i>
              </button>
              */}
            </div>
            <div className="header-option">
              <div className="option-grid">
                <div className="option-group">
                  <input type="text" placeholder="City" />
                </div>
                <div className="option-group">
                  <input type="text" placeholder="State" />
                </div>
                <div className="option-group">
                  <input type="text" placeholder="Min Price" />
                </div>
                <div className="option-group">
                  <input type="text" placeholder="Max Price" />
                </div>
                <button type="submit">
                  <i className="fas fa-search"></i>
                  <span>Search</span>
                </button>
              </div>
            </div>
          </form>
          <div className="header-right">
            <ul className="header-list">
              <li className="header-item">
                <Notifications />
              </li>
            </ul>
            <Link to={newAdLink} className="btn btn-inline post-btn">
              <i className="fas fa-plus-circle"></i>
              <span>Postavi oglas</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default withStore(['auth'])(Header);
