import React from 'react';
import { observer } from 'mobx-react';

import Store from './Store';

const withStore = (store) => {
  const storeProps = {}

  if (Array.isArray(store)) {
    store.forEach((el) => {
      storeProps[el] = Store[el];
    });
  } else {
    storeProps[store] = Store[store];
  }

  return (component) => {
    return observer((props) => component({ ...props, ...storeProps }));
  }
}

export default withStore;
