import React from 'react';
import { Link, Routes, Route, Navigate } from 'react-router-dom';

import { withStore } from '../../store';
import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import EditAd from './EditAd/EditAd';
import MyAds from './MyAds/MyAds';
import Settings from './Settings/Settings';

const Profile = (props) => {
  return (
    <>
      <MainLayout>
        <PageBanner title="Profil" />
        <ProfileHeader
          name={props.auth.user.name}
          email={props.auth.user.email}
          phone={props.auth.user.phone} />
        <Routes>
          <Route path="/new_ad" element={<EditAd />} />
          <Route path="/ads" element={<MyAds />} />
          <Route path="/ads/:id/edit" element={<EditAd />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
        <br />
        <br />
      </MainLayout>
    </>
  );
}

export default withStore(['auth'])(Profile);
