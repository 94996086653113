import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Autocomplete from "react-google-autocomplete";

import { withStore } from '../../../store';
import { urlToImage } from '../../../utils';
import * as constants from '../../../constants';
import Spinner from '../../../components/Spinner/Spinne';
import MapEditor from '../../../components/MapEditor/MapEditor';

const EditAd = (props) => {
  const { id } = useParams();

  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priceType, setPriceType] = useState(0);
  const [price, setPrice] = useState(0);
  const [terms, setTerms] = useState(false);
  const [images, setImages] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [place, setPlace] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const currentAd = props.ad.currentAd;

  useEffect(() => {
    props.category.load();
    if (id) {
      props.ad.loadCurrentAd(id);
    }
  }, []);

  useEffect(async () => {
    if (id && currentAd) {
      //console.log(currentAd);
      setType(currentAd.type?.slug);
      setCategory(currentAd.category?.id);
      setSubcategory(currentAd.subcategory?.id);
      setTitle(currentAd?.title);
      setDescription(currentAd?.description);
      setPriceType(currentAd?.priceType);
      setPrice(currentAd?.price);
      setTerms(true);

      setPlace(currentAd?.address?.place);
      setAddress(currentAd?.address?.address);
      if (currentAd?.address) setLat(currentAd.address.lat);
      if (currentAd?.address) setLng(currentAd.address.lng);

      /*
      let imgs = [];
      if (currentAd.images) {
        imgs = await Promise.all(currentAd.images.map(async (el) => {
          return await urlToImage(`${constants.IMAGE_BASE_URL}/${el}`, el);
        }));
      }
      setImages(imgs);
      */
    }
  }, [currentAd]);

  useEffect(() => {
    setCategory('');
    setSubcategory('');
  }, [type]);

  /*
  useEffect(() => {
    setSubcategory('');
  }, [category]);
  */

  useEffect(() => {
    if (isLoading) {
      save();
    }
  }, [isLoading]);

  const save = () => {
    if (!terms) {
      setIsLoading(false);
      alert('Nije moguće postaviti oglas ukoliko se ne slažete sa našim uslovima korišćenja!');
      return;
    }

    if (!type || type.length === 0) {
      setIsLoading(false);
      alert('Odaberite tip oglasa!');
      return;
    }

    if (!category || !subcategory || category.length === 0 || subcategory.length === 0) {
      setIsLoading(false);
      alert('Odaberite kategoriju oglsa');
      return;
    }

    if (!price || price === null || price === '') {
      price = 0;
    }

    const data = {
      title,
      type: props.category.categories.find(el => el.id === category).type.id,
      category,
      subcategory,
      price,
      priceType,
      description,
      place,
      address,
      lat,
      lng,
    }

    if (id) {
      data.id = id;
      props.ad.updateAd(props.auth.token, data, images)
        .then(() => {
          setIsDone(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      props.ad.insertAd(props.auth.token, data, images)
        .then(() => {
          setIsDone(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }

  /*
  const fileSelectHandler = (event) => {
    const updatedImages = [
      ...images,
      ...event.target.files,
    ];

    setImages(updatedImages);
  }
  */

  const fileSelectHandler = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles);
    setImages(pictureFiles);
  }

  const categories = props.category.categories.filter(el => el.type.slug === type).map((el) => {
    return (
      <option key={el.id} value={el.id}>{el.title}</option>
    );
  });

  const subcategories = props.category.categories.find(el => el.id === category)?.subcategories.map((el) => {
    return (
      <option key={el.id} value={el.id}>{el.title}</option>
    );
  });

  const priceTypes = constants.PRICE_TYPES.map((el) => {
    return (
      <option key={el.id} value={el.id}>{el.label}</option>
    );
  });

  if (isDone) {
    return (
      <Navigate to="/profile/ads" />
    );
  }

  let submit = (
    <button className="btn btn-inline">
      <i className="fas fa-check-circle"></i>
      <span>Objavi oglas</span>
    </button>
  );

  if (isLoading) {
    submit = (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <section className="adpost-part">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <form className="adpost-form" onSubmit={(e) => { e.preventDefault(); setIsLoading(true); }}>
              <div className="adpost-card">
                <div className="adpost-title">
                  <h3>Oglas</h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">Naslov</label>
                      <input type="text" className="form-control" placeholder="Naslov oglasa" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">Fotografije</label>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText='Odaberi fotografiju'
                        onChange={fileSelectHandler}
                        imgExtension={['.jpg', '.jpeg', '.png']}
                        maxFileSize={5242880} />
                    </div>
                  </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">Tip oglasa</label>
                        <select className="form-control custom-select" value={type} onChange={(e) => setType(e.target.value)}>
                          <option>Odaberi tip</option>
                          <option value="renta">Renta</option>
                          <option value="usluge">Usluga</option>
                          <option value="razmena">Razmena</option>
                          <option value="pokloni">Poklon</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label">Kategorija</label>
                        <select className="form-control custom-select" value={category} onChange={(e) => setCategory(e.target.value)}>
                          <option>Odaberi kategoriju</option>
                          {categories}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label">Podkategorija</label>
                        <select className="form-control custom-select" value={subcategory} onChange={(e) => setSubcategory(e.target.value)}>
                          <option>Odaberi podkategoriju</option>
                          {subcategories}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label">Obračun</label>
                        <select className="form-control custom-select" value={priceType} onChange={(e) => setPriceType(e.target.value)}>
                          <option>Odaberi način obračuna</option>
                          {priceTypes}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-label">Cena</label>
                        <input type="number" className="form-control" placeholder="Cena" value={price} onChange={(e) => setPrice(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">Opis oglasa</label>
                        <textarea className="form-control" placeholder="Opsi oglasa" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="adpost-card pb-2">
                  <div className="adpost-card">
                    <div className="adpost-title">
                      <h3>Adresa</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-label">Mesto</label>
                          <br />
                          <input type="text" className="form-control" placeholder="Mesto" value={place} onChange={(e) => setPlace(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-label">Adresa (opciono)</label>
                          <br />
                          <input type="text" className="form-control" placeholder="Adresa" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <MapEditor
                  lat={lat}
                  lng={lng}
                  onLocationUpdated={(lat, lng) => { setLat(lat); setLng(lng); }} />
                <div className="adpost-card pb-2">
                  <div className="adpost-agree">
                    <div className="form-group">
                      <input type="checkbox" className="form-check" value={terms} onChange={(e) => setTerms(e.target.checked)} checked={terms} />
                    </div>
                    <p>Prihvatam uslove korišćenja</p>
                  </div>
                  <div className="form-group text-right">
                    {submit}
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withStore(['auth', 'category', 'ad'])(EditAd);
