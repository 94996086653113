import { runInAction, makeAutoObservable } from 'mobx';

class Search {
  ads = []
  search = {}

  constructor() {
    makeAutoObservable(this);
  }

  clear = () => {
    this.search = {}
    this.ads = []
  }

  setType(type) {
    this.search.type = type;
  }

  setCategory(category) {
    this.search.category = category;
  }

  setSubcategory(category) {
    this.search.subcategory = category;
  }
}

export default Search;
