import Auth from './Auth';
import Category from './Category';
import Ad from './Ad';
import Search from './Search';

const Store = {
  auth: new Auth(),
  category: new Category(),
  ad: new Ad(),
  search: new Search(),
}

export default Store;
