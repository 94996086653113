import React from 'react';

import './Spinner.css';

const Spinner = (props) => {
  return (
    <div className="lds-heart">
      <div></div>
    </div>
  );
}

export default Spinner;
