import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/img/logo.png';

const AuthBanner = (props) => {
  return (
    <div className="user-form-banner">
      <div className="user-form-content">
        <Link to="/"><img src={logo} alt="logo" /></Link>
        <h1>Pronađi sve na jednom mestu</h1>
        <p>Renta, Razmena, Usluge, Pokloni</p>
      </div>
    </div>
  );
}

export default AuthBanner;
