import React from 'react';

import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';
import About from '../../components/AboutUs/AboutUs';
import WhyWe from '../../components/WhyWe/WhyWe';

const AboutUs = (props) => {
  return (
    <MainLayout>
      <PageBanner title="O nama" small />
      <About detailed />
      <WhyWe />
    {/*
      <section className="team-part">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-center-heading">
                <h2>Pravni zastupnik</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="team-card">
                <img src="images/team/01.jpg" alt="team" />
                <h5><a href="#">Jhonson Hononr</a></h5>
                <p>Founder &amp; CEO</p>
                <ul>
                  <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}
      <br />
      <br />
    </MainLayout>
  );
}

export default AboutUs;
