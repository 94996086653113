import React, { useState, useEffect } from 'react';

import { withStore } from '../../../store';
import * as constants from '../../../constants';
import Spinner from '../../../components/Spinner/Spinne';

const Settings = (props) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!props.auth.user) {
      return;
    }

    setName(props.auth.user.name);
    setPhone(props.auth.user.phone);
  }, []);

  let submit = (
    <button className="btn btn-inline">
      <i className="fas fa-check-circle"></i>
      <span>Sačuvaj izmene</span>
    </button>
  );

  if (isLoading) {
    submit = (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <section className="adpost-part">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <form className="adpost-form" onSubmit={(e) => { e.preventDefault(); setIsLoading(true); }}>
              <div className="adpost-card">
                <div className="adpost-title">
                  <h3>Izmena podataka</h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">Ime</label>
                      <input type="text" className="form-control" placeholder="Naslov oglasa" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">Telefon</label>
                      <input type="text" className="form-control" placeholder="Naslov oglasa" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="form-group text-right">
                  {submit}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withStore(['auth'])(Settings);
