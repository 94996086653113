import React from 'react';
import { useNavigate } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';

import ReactCardSlider from './ReactCardSlider';

import * as constants from '../../constants';

const CategoriesSlider = (props) => {
  const navigate = useNavigate();

  const slides = props.categories.map((el) => {
    return {
      image: el.image ? `${constants.IMAGE_BASE_URL}/${el.image}` : `${constants.PUBLIC_URL}/images/default_ad.png`,
      title: el.title,
      description: '',
      clickEvent: () => navigate(`/search?type=${el.type.slug}&category=${el.slug}`),
    }
  });

  return (
    <section className="about-part">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3 style={{ textAlign: 'center' }}>{props.title}</h3>
            <br />
            {props.description}
            <br />
            <ReactCardSlider slides={slides} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CategoriesSlider;
