import React from 'react';

const AboutUs = (props) => {
  return (
    <section className="about-part">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-content">
              <h2>Šta su Babe Žabe</h2>
              <p>Babe  Žabe   su   jedninstveni   projekat   u  Srbiji  i  regionu ,  koji   vam  omogućava   da  izaberete  Kategoriju  u   kojoj  želite   da  postavite   Oglas. Bez  provizije  i   bez  posrednika.  Iskoristite   promotivni   period   Platforme   i   postavljajte  vaše   Oglase   do   mile   volje.</p>
              <br />
              <p>{props.detailed && 'Platforma  Babe-Žabe   ( u  daljem  tekstu  platforma)  je  elektronska   platforma   namenjena   onlajn oglašavanju. Cilj platforme je da na jednom mestu ponudi  mogućnost  za  oglašavanje  nekoliko  različitih  tipova  oglasa.  Za  pristup  platformi  neophodna  je  veza  sa  internetom  i  veb  pregledač.  Tipovi  oglasa  koje  je  moguće  oglašavati  na  platformi  su:    1.  Rentiranje     2.Usluge    3. Razmena    4. Pokloni    Rentiranje  predstavlja  tip  oglasa   u  kojem  se  nudi  određeni  predmet  na  rentiranje  u  zamenu  za  novčanu  naknadu.  Usluge  predstavljaju  tip  oglasa  u  kojem  se  nudi  određena  usluga  u  zamenu  za novčanu  naknadu.  Poklon  predstavlja  tip  oglasa  u  kojem  se  nudi  određeni  predmet  kao  poklon.  Oglašivač  nudi  predmet  za  koji  ne  očekuje  nikakvu novčanu  naknadu.  Razmena  predstavlja  tip  oglasa  u  kojem  se  nudi  određeni  predmet,  ali  umesto  novčane  naknade  oglašivač  očekuje  da  dobije  drugi   predmet  za  uzvrat.  Zainteresovane  stranke  mogu  da  se  jave  oglašivaču  i  daju  svoju  ponudu  za  trampu.  Ograšavanje  na  platformi  moguće  je samo  registrovanim  korisnicima.  Oglašivač  i  zainteresovana  strana  imaju  mogućnost  da  razmenjuju  poruke  kroz  sistem  za  razmenu  poruka  platforme. Oglasi  u  okviru  različitih  tipova  grupisani  su  u  određene  kategorije.  Neke  od  kategorija  mogu  biti  građevinske  mašine,  alati,  muzički uređaji,  automobili,  nekretnine  i  druge.  Pretraga  oglasa  moguća  je  uz  pomoć  unosa  ključnih  reči  ili  odabira  oblasti.   Platforma  poseduje  mogućnost  napredne  pretrage  što  podrazumeva  sortiranje  i  filtriranje  Oglasa  uz  pomoć  različitih  kriterijuma.   Na  primer,  moguće  je  naći  samo  oglase  koji  se  nalaze  u zadatom  cenovnom  rangu,  ili  sortirati  rezultat  pretrage  po  rastućim  ili  opadajućim  cenama.'}    </p>
    {/*}<h2>Ukratko o nama</h2>*/}
    {/*}<p>BABE ŽABE je NOVA online zajednica na tržištu, namenjena povezivanju onih koji žele nešto da rentiraju,razmenjuju, koriste ili pružaju usluge i daruju poklone. Babe Žabe nudi širok spektar unapređenih mogućnosti, jednostavnih za upotrebu (bez provizije i bez posrednika)</p>*/}
            </div>
            <div className="about-quote">
              <p>Naša misija je da na jednom mestu objedinimo ponudu i potražnju za sve i svašta! </p>
              <h5>Tim - <span>Babe Žabe</span></h5>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row about-image">
              <div className="col-6 col-lg-6">
                <img src="images/about/01.jpg" alt="about" />
              </div>
              <div className="col-6 col-lg-6">
                <img src="images/about/02.jpg" alt="about" />
              </div>
              <div className="col-6 col-lg-6">
                <img src="images/about/03.jpg" alt="about" />
              </div>
              <div className="col-6 col-lg-6">
                <img src="images/about/04.jpg" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
