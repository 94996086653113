import React from 'react';
import { Link } from 'react-router-dom';

const Types = (props) => {
  const types = [
    {
      id: 0,
      title: 'Renta',
      url: '/categories/renta',
    },
    {
      id: 1,
      title: 'Usluge',
      url: '/categories/usluge',
    },
    {
      id: 2,
      title: 'Razmena',
      url: '/categories/razmena',
    },
    {
      id: 3,
      title: 'Pokloni',
      url: '/categories/pokloni',
    },
  ];

  /*
  const typesComponents = types.map((el) => {
    return (
      <Link key={el.id} to={el.url} className="suggest-card" style={{width: '120px'}}>
        <div>&nbsp;</div>
        <h6>{el.title}</h6>
        <p>&nbsp;</p>
      </Link>
    );
  });
  */
  const typesComponents = (
    <>
      <br />
      <br />
      <br />
    </>
  );

  return (
    <section className="suggest-part">
      <div className="container">
        <div className="suggest-slider slider-arrow" style={{textAlign: 'center'}}>
          {typesComponents}
        </div>
      </div>
    </section>
  );
}

export default Types;
