import React from 'react';

import MainLayout from '../../hoc/Layouts/MainLayout';
import PageBanner from '../../components/PageBanner/PageBanner';

const Terms = (props) => {
  return (
    <MainLayout>
      <PageBanner title="Uslovi korišćenja" small />
      <div class="setting-part">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="account-card alert fade show">
                <div class="account-title">
                  <h3>Pravila i uslovi korišćenja</h3>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>KORIŠĆENJE SAJTA</h5>
                  <p>Uslovima korišćenja se uređuju prava i obaveze u vezi sa korišćenjem www.babezabe.com online zajednice.  Online zajednica BABE ŽABE se nalazi na internet adresi https://babezabe.com i predstavlja uslugu informacionog društva , kojoj se pristupa putem interneta).</p>
                  <p>Ovi uslovi korišćenja predstavljaju odredbe ugovora koji se zaključuje između pružaoca usluge i svakog pojedinog korisnika ovih usluga. Prilikom pristupanja online zajednici korisnik prihvata sva pravila i uslove korišćenja koji su ovde definisani, te na taj način zaključuje ugovor po pristupu sa pružaocem usluge informacionog društva. Ova online  zajednica omogućava korišćenje svojih usluga fizičkim i pravnim licima, isključivo na način i pod uslovima opisanim u ovim uslovima korišćenja, na način i pod kojima se pruža usluga informacionog društva.</p>
                  <p>Na svaki pristup sadržajima online zajednice, primenjuju se ovi uslovi korišćenja.
Poslovanje je regulisano prevashodno Zakonom o elektronskoj trgovini, Zakonom o obligacionim odnosima, Zakonom o oglašavanju, Zakonom o autorskom i srodnim pravima, Zakonom o žigovima, Zakonom o zaštiti podataka o ličnosti, kao i drugim propisima pravnog sistema Republike Srbije u delovima koji nisu regulisani navedenim zakonima.
Online  zajednica je posvećena očuvanju i primeni prava zaštiti podataka o ličnosti koja uživaju fizička lica, kao i autorskih prava, a u svemu prema pravilima informacione struke u skladu sa važećim propisima Republike Srbije.</p>
                  <p>Online zajednica ni na koji način ne utiče na sadržaj koji postavljaju korisnici (osim uz prethodnu saglasnost korisnika koji je sadržaj postavio) ili koji je nastao skupljanjem javno dostupnih podataka, niti garantuje za tačnost ili upotrebljivost informacija koje su prikazane kroz dostupan sadržaj. Isključivu odgovornost za sadržaj imaju ona lica koja sadržaj postavljaju odnosno od kojih je sadržaj preuzet.</p>
                  <p>Online zajednica ima pravo, ali ne i obavezu, da bilo koji sadržaj, u bilo kom trenutku ukloni sa digitalne platforme, bez obaveze bilo kakvog prethodnog ili naknadnog obaveštenja ili obrazloženja.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>OPIS ONLINE ZAJEDNICE</h5>
                  <p>Online zajednica ovim putem pruža uslugu informacionog društva.
Ove usluge su namenjene kako onima koji oglašavaju dobra i usluge, tako i onima koji ih traže radi zakupa ili rentiranja.  Korisnicima je omogućen internet pristup sadržaju koji je nastao prikupljanjem javno dostupnih podataka i sadržaju postavljenom od strane korisnika. Oglašavanje je omogućeno pod uslovima i na način propisanim Zakonom o oglašavanju.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>KORISNICI</h5>
                  <p>Korisnicima usluga smatraju se posetioci i registrovani korisnici (pravna i fizička lica).
Posetilac je lice koje putem Interneta pristupi online zajednici u smislu ovih Uslova korišćenja:
Bez prijave ili registracije posetilac može da se upozna sa celokupnim sadržajem dostupnim na njoj, bez plaćanja bilo kakve naknade.</p>
                  <p>Registrovani korisnik je pravno ili fizičko lice koje se registrovalo pod uslovima i na način iz ovih Uslova korišćenja.</p>
                  <p>Registrovani korisnik može postavljati i objavljivati sopstveni sadržaj: komentare i ocene.
Registrovani korisnik ima isključivu odgovornost i snosi sve pravne posledice koje iz njegovog mogu da proisteknu.</p>
                  <p>Registrovani korisnik zadržava sva autorska i srodna prava na sadržaj kojeg je autor. Registrovani korisnik garantuje da je vlasnik, odnosno korisnik, svih neophodnih autorskih prava na celokupnom sadržaju, kao i svim njegovim pojedinim delovima, koji postavlja. Za svako eventualno kršenje autorskog ili srodnog prava, kao i drugih prava intelektualne svojine, odgovara isključivo registrovani korisnik koji je sadržaj postavio. Postavljanjem sadržaja, korisnik dozvoljava online zajednici da bez ikakve naknade isti iskoristi za sopstvene promotivne ili edukativne potrebe i da ga u tom smislu može izmeniti i modifikovati prema svrsi tih potreba, bez navođenja imena odnosno podataka o autoru sadržaja.
Registrovani korisnik može istovremeno postavljati različit sadržaj. Registrovani korisnik može bez bilo kakvog obrazloženja ili obaveštenja, u bilo koje doba, obrisati sadržaj koji je postavio. </p>
                  <p>Svakom pojedinačnom prijavom, registrovani korisnik dobija pristup svom nalogu na njemu i mogućnost postavljanja sadržaja. Registrovani korisnik može da podesi svoj nalog, te mu na taj način dodeli ili promeni ime.</p>
                  <p>Postavljanjem sadržaja na digitalnu platformu registrovani korisnik bezuslovno i neopozivo ovlašćuje Online zajednicu da sadržaj prenese neodređenom broju lica, kao i da sadržaj ukloni bez prethodnog ili naknadnog obaveštenja ili obrazloženja.
Ukoliko se korisnik ne slaže sa uslovima koji si ovde definisani, ima pravo da prestane s korišćenjem ovih usluga.</p>
                  <p>Registrovani korisnik može bez bilo kakvog obrazloženja ili obaveštenja, u bilo koje doba ukinuti svoj status registrovanog korisnika podnošenjem zahteva za deaktivaciju svog korisničkog naloga.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>PRAVILNIK O ZAŠTITI PODATAKA O LIČNOSTI</h5>
                  <p>Pravilnik o privatnosti je sastavni deo ovih Uslova korišćenja.
Vrši se obrada neophodnih podataka onih lica koja su dala pristanak za obradu, i za to jasno određenu svrhu, na zakonom dozvoljen način, tako da lice na koje se podaci odnose ne bude određeno ili odredivo i nakon što se ostvari svrha obrade, a u srazmeri sa svrhom obrade.
Podaci koji se obrađuju moraju biti istiniti i potpuni, kao i da se zasnivaju na verodostojnom izvoru, odnosno izvoru koji nije zastareo.</p>
                  <p>Pre prikupljanja podataka, online zajednica u svojstvu rukovaoca podacima obaveštava lice na koje se podaci odnose o sledećem:</p>
                  <ul style={{ marginLeft: 25 }}>
                    <li>- Da je lice odgovorno za prikupljanje podataka pružaoc usluge.</li>
                    <li>- Da je svrha prikupljanja i obrade podataka omogućavanje pružanja usluge informacionog društva, bezbednost korisnika Internet Servisa, kao i omogućavanje kreiranja i postavljanje sadržaja u skladu sa zakonom.</li>
                    <li>- Da se prikupljeni podaci koriste na način koji omogućava identifikaciju lica koja oglašavaju dobra I usluge, a u skladu sa čuvanjem poslovne tajne i zakonom.</li>
                    <li>- Da su lica koja koriste podatke ona lica koja su zaposlena u pružaocu usluge i svim direktno povezanim pravnim licima i imaju ovlašćenje za korišćenje podataka, kao i svi Državni organi koji na osnovu zakona i/ili odluke suda ostvaruju pravo korišćenja podataka.</li>
                    <li>- Da su lica koja koriste podatke sva lica koja su angažovana kod pružaoca usluge i svim direktno povezanim licima i imaju ovlašćenje za korišćenje podataka kao i svi Državni organi koji na osnovu zakona i/ili odluke suda ostvaruju pravo korišćenja podataka.</li>
                    <li>- Da je davanje podataka o ličnosti dobrovoljno, te da niko ko ne želi da bude registrovani korisnik digitalne platforme nije u obavezi da ostavi bilo kakav podatak.</li>
                    <li>- Da lice koje je dalo pristanak o prikupljanju i obradi podataka može u bilo koje doba i bez obrazloženja povući svoj pristanak, što kao pravnu posledicu proizvodi prestanak mogućnosti korišćenja digitalne platforme, dospevanje svih neizmirenih obaveza nastalih za vreme trajanja korišćenja digitalne platforme, kao i da time prestaje svako dalje prikupljanje podataka od lica koje je pristanak povuklo.</li>
                    <li>- Da u slučaju nedozvoljene obrade lica imaju prava na zaštitu podataka o ličnosti, kao i drugih prava koja im po zakonu pripadaju.</li>
                  </ul>
                  <p>Podaci koji se prikupljaju u prethodno naznačene svrhe su: ime, prezime, e-mail adresa,država, grad, telefon,  broj platne kartice</p>
                  <p>Sva opšta akta moraju biti u skladu sa ovim Pravilnikom o zaštiti podataka o ličnosti. U suprotnom primeniće se odredbe ovog Pravilnika.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>REKLAMACIJE</h5>
                  <p>S obzirom da se radi o uslugama informacionog društva, na izvršenu uslugu nije moguće izjavljivanje reklamacije, niti zamena za drugu uslugu.
Usluga se smatra u potpunosti izvršenom nakon izričite prethodne saglasnosti korisnika da uslugu koristi.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>AUTORSKO PRAVO</h5>
                  <p>Informaciono društvo ima isključivo autorsko pravo i prava intelektualne svojine na onlajn zajednici, kao i na svim pojedinim elementima koji ga čine, kao što su: tekst, vizuelni i audio elementi, vizuelni identitet, podaci i baze podataka, programski i drugi elementi servisa, kojih je autor.</p>
                  <p>Neovlašćeno korišćenje bilo kog dela navedenog sadržaja bez izričite prethodne dozvole u pisanoj formi izdate od društva kao nosioca isključivih autorskih prava, smatraće se povredom autorskih prava i podložno je pokretanju svih postupaka u punoj zakonskoj meri.
Online zajednica može sadržati i elemente na kojima isključiva autorska, žigovna i druga prava intelektualne svojine imaju druga lica, kao što su sadržaji korisnika, sadržaj poslovnih partnera, oglašivača i slično. Druga lica imaju isključivu odgovornost za sadržaj na kojem su nosioci tih prava, bez obzira na to što se takav sadržaj nalazi na internet adresi ove online zajednice.</p>
                  <p>Postavljanjem sadržaja, korisnik je saglasan da isti postane vidljiv svakom posetiocu online zajednice kao posetiocima drugih pružalaca usluga informacionog društva. Dalje prenošenje sadržaja drugih lica ili dela sadržaja je dozvoljeno samo uz obaveznu izričitu prethodnu saglasnost online zajednice, uz napomenu da je sadržaj preuzet sa naznačene internet adrese, uz naznačenje odgovarajućeg linka na kojem se preuzeti sadržaj nalazi. Online zajednica ima isključivu odgovornost samo za sadržaj na kojem ima autorska prava.
Svako lice samostalno snosi odgovornost za sadržaj koji je njegovo autorsko delo, odnosno za sadržaj koji je samostalno postavilo i na taj način učinilo javno dostupno.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>SMERNICE</h5>
                  <p>Registrovani korisnici imaju obavezu da prilikom izrade sadržaja poštuju navedene smernice. 
Korisnik odgovara za postavljeni sadržaj, a naročito ako taj sadržaj, uključuje:</p>
                  <ul style={{ marginLeft: 25 }}>
                    <li>- unošenje sadržaja u pogrešne rubrike (npr. navođenje broja telefona u polje koje nije predviđeno za to)</li>
                    <li>- pogrešno kategorisanje sadržaja (npr. postavljanje oglasa koji nisu relevantni za kategoriju u kojoj se oglašavaju)</li>
                    <li>- ako se radi o duplom oglasu (identičan oglas objavljen više puta) kada je predmet oglašavanja iznajmljivanje ili rentiranje (iznamljivanje stana , rentiranje bicikla …itd)</li>
                    <li>- unošenjem neispravnih slika u oglas (npr. lošeg kvaliteta; sa naknadno dodatim kontakt telefonom, logotipom drugog sajta ili firme, tekstom iznajmljeno i sl.)</li>
                    <li>- otvoreno uvredljiv sadržaj ili sadržaj koji promoviše rasizam, netrpeljivost, mržnju ili fizičko povređivanje bilo koje vrste, a koje je usmereno na bilo koju grupu ili pojedinca</li>
                    <li>- uznemirava, ili promoviše uznemiravanje druge osobe</li>
                    <li>- eksploatiše ljude na seksualni ili nasilni način</li>
                    <li>- sadrži nagost, preterano nasilje ili uvredljiv sadržaj ili sadrži vezu do veb-sajtova za odrasle</li>
                    <li>- traži lične informacije od osoba mlađih od 18 godina</li>
                    <li>- javno objavljuje informacije koje predstavljaju ili stvaraju rizik po privatnost ili bezbednost bilo koje osobe</li>
                    <li>- sadrži ili promoviše informacije za koje znate da su netačne ili koje navode na pogrešan zaključak ili promovišu nedozvoljene aktivnosti ili čiji je sadržaj uvredljiv, zastrašujući, pun pretnji, nepristojan ili klevetnički</li>
                    <li>- sadrži ili promoviše nedozvoljenu ili neovlašćenu kopiju zaštićenog rada druge osobe</li>
                    <li>- uključuje prenos neželjene pošte, cirkularnih pisama ili masovne pošte, instant poruka, odnosno „spam“</li>
                    <li>- sadrži stranice sa ograničenim pristupom ili stranice kojima je moguće pristupiti samo uz pomoć lozinke ili skrivene stranice ili slike (one koje nisu povezane sa drugim stranicama)</li>
                    <li>- podstiče ili promoviše kriminalne aktivnosti ili poslove ili pruža uputstva za obavljanje nedozvoljenih aktivnosti, uključujući, ali ne ograničavajući na izradu ili kupovinu ilegalnog oružja, kršenje nečije privatnosti ili pronalaženje ili kreiranje računarskih virusa</li>
                    <li>- traži lozinke ili informacije koje vas lično identifikuju u komercijalne ili nedozvoljene svrhe od drugih korisnika</li>
                    <li>- uključuje druge komercijalne aktivnosti i/ili izdavanje van svrhe digitalne platforme, bez prethodne pisane saglasnosti pružaocem usluge kao što su takmičenja, lutrija sa nagradama u robi i uslugama ili piramidalne šeme</li>
                    <li>- uključuje fotografiju ili video zapis druge osobe koju ste objavili bez pristanka te osobe</li>
                    <li>- krši prava na privatnost, prava na javno objavljivanje, pravo na zaštitu od klevete, autorska prava, pravo na žig, prava iz ugovora, ili neka druga lična prava.krši prava na privatnost, prava na javno objavljivanje, pravo na zaštitu od klevete, autorska prava, pravo na žig, prava iz ugovora, ili neka druga lična prava.</li>
                  </ul>
                  <p>Aktivnosti koje su nedozvoljene ili su zabranjene:</p>
                  <ul style={{ marginLeft: 25 }}>
                    <li>- kriminalne ili deliktne aktivnosti, uključujući dečiju pornografiju ili erotiku, prevare, distribuciju pornografskog sadržaja, distribuciju ili uživanje droge, kockanje, uznemiravanje, uhođenje, “spam“, piramidalne šeme, Ponzi šeme, slanje virusa ili drugih štetnih datoteka, kršenje autorskih prava, povredu patenta ili krađu poslovne tajne</li>
                    <li>- oglašavanje korisnicima ili nuđenje da kupe ili prodaju bilo koje proizvode ili usluge preko neovlašćenih ili nedozvoljenih načina korišćenja </li>
                    <li>- pokušaj da se zaobiđe ili izmeni, podsticanje ili pomaganje drugim osobama da zaobiđu ili izmene neku od bezbednosnih tehnologija ili softvera koji se koriste</li>
                    <li>- falsifikovanje zaglavlja TCP/IP paketa ili bilo kog dela informacija u zaglavlju u bilo kojoj objavi ili na bilo koji drugi način korišćenje onlne zajednice za slanje izmenjenih, prevarantskih ili pogrešnih informacija koje identifikuju izvor</li>
                    <li>- aktivnosti koje uključuju korišćenje virusa, botova, crva ili nekih drugih računarskih kodova, datoteka ili programa koji prekidaju, uništavaju ili ograničavaju funkcionisanje računarskog softvera ili hardvera, ili na drugi način dozvoljavaju neovlašćeno korišćenje ili pristup računaru ili računarskoj mreži</li>
                    <li>- mešanje u pristup online zajednici bilo kog korisnika, hosta ili mreže</li>
                    <li>- prekrivanje ili zamagljivanje oglašavanja preko banera na stranici profila korisnika ili bilo kojoj stranici online zajednice preko HTML/CSS-a ili na neki drugi način</li>
                    <li>- uključujući HTML, CSS ili neko drugo kodiranje na stranici profila korisnika, uključujući, ali ne ograničavajući se na sve skrivene ili na drugi način potajno sadržane kodove u poslatom sadržaju koji nije povezan sa prirodom poslatog sadržaja</li>
                    <li>- bilo koje automatsko korišćenje sistema, ali ne ograničavajući se na korišćenje skripti za slanje slika ili video zapisa</li>
                    <li>- mešanje, prekidanje ili kreiranje nepotrebnog opterećenja digitalne platforme, na mreži ili uslugama povezanim sa njom</li>
                    <li>- imitiranje ili pokušaj imitacije pravnog ili fizičkog lica</li>
                    <li>- korišćenje pristupnih podataka ili korisničkog imena drugog korisnika u bilo kom trenutku ili odavanje lozinke trećim licima ili dozvola trećim licima da pristupe korisničkom nalogu koji nije njihov</li>
                    <li>- prodaja ili prenos korisničkog profila na neki drugi način</li>
                    <li>- korišćenje informacija dobijenih pristupom online zajednici kako bi se maltretiralo, zlostavljalo ili povredilo neko lice i pokušaj da se to uradi</li>
                    <li>- nedozvoljeni komercijalni oglas na korisničkom profilu, prihvatanje uplate ili bilo čega što ima vrednost od trećih lica u zamenu za obavljanje komercijalnih aktivnosti preko neovlašćenog ili nedozvoljenog korišćenja online zajednice u ime te osobe</li>
                    <li>- brisanje ili drugi načini izmene obaveštenja o autorskim pravima, žigovima ili drugim pravima vlasništva koja se pojavljuju na korisničkom sadržaju, osim ako je to sadržaj koji korisnik postavlja</li>
                    <li>- korišćenje meta oznaka ili drugog skrivenog teksta ili metapodataka pomoću imena, žiga, URL adresa ili naziva proizvoda društva bez prethodne izričite dozvole</li>
                    <li>- pokušaj da se ispita, skenira ili testira ranjivost bilo kog dela digitalne platforme ili prekrše bilo koje bezbednosne mere ili mere provere identiteta</li>
                    <li>- prikupljanje ili skladištenje ličnih podataka o drugim korisnicima bez njihove izričite dozvole</li>
                    <li>- imitiranje ili lažno predstavljanje povezanosti sa nekim licem, preko unapred poslatog teksta ili drugog oblika socijalnog inženjeringa ili drugi vidovi prevare</li>
                    <li>- korišćenje Usluga na način koji nije u skladu sa bilo kojim važećim zakonima i odredbama, povreda servisa ili bezbednosti mreže.</li>
                  </ul>
                  <p>Pružaoc usluge ima pravo, ali ne i obavezu da nadgleda sve korisničke aktivnosti i korisnički sadržaj koji oni postavljaju. Pružalac usluge može istražiti sva prijavljena kršenja svojih smernica i drugih prijava i može preduzeti bilo koje pravne ili tehničke radnje koje smatra odgovarajućim. Takođe će istražiti okolnosti koje mogu uključivati ta kršenja i može da pozove na saradnju i sarađuje sa nadležnim državnim organima tokom identifikacije, istrage, odnosno krivičnog gonjenja lica koja su uključena u to kršenje smernica, odnosno kršenje zakona. Pružalac usluge zadržava diskreciono pravo da koristi sva pravna sredstva, uključujući, ali ne ograničavajući se na uklanjanje korisničkog naloga i korisničkog sadržaja, kao i da odmah obustavi sve usluge koje korisnik koristi, u slučaju bilo kakvog kršenja ovih Uslova ili u slučaju da društvo ne može da verifikuje ili da proveri neku informaciju koju mu korisnik pošalje.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>OGLAŠAVANJE I OGLASNA PORUKA</h5>
                  <p>Prenosilac oglasne poruke je društvo kao pružalac usluge informacionog društva i pruža uslugu oglašavanja isključivo putem Interneta.
Za sadržaj, tačnosti i ispravnost, kao i validnost, odnosno dozvoljenost oglasne poruke odgovornost snosi isključivo lice koje je oglasnu poruku unelo. Za sve pravne posledice koje nastanu unosom oglasne poruke, odgovara isključivo lice koje je oglasnu poruku unelo.
Pružalac usluge zadržava pravo da ne objavi sadržaj kojim se krše odredbe zakona Republike Srbija, a naročito Zakon o oglašavanju. U slučaju prijema takvog oglasa, kontaktiraće se oglašivač radi izmene oglasne poruke. Pružalac usluge može u svakom trenutku, po sopstvenom nahođenju, da odbije postavljanje bilo koje oglasne poruke kojom se krše odredbe Zakona ili ovih Uslova korišćenja, ali ne odgovara za sadržaj takve poruke, već za sadržaj odgovara isključivo lice koje se oglašava, odnosno koje je takav sadržaj dostavilo.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>POSTAVLJANJE OGLASNIH PORUKA- OGLASA</h5>
                  <p>Registrovani korisnici koji oglašavaju dobra ili uslugu, oglas dostavljaju putem formulara za naručivanje oglasa putem online zajednice. Prema Zakonu o oglašavanju ne postoji obaveza dostavljanja Deklaracije o oglašavanju za oglašavanje putem Interneta. Ukoliko su sva obavezna polja iz formulara za naručivanje oglasa pravilno popunjena i ako je oglas potpun on se nakon toga objavljuje.
Oglasi onih korisnika koji se prvi put oglašavaju su vidljivi odmah nakon aktivacije oglasa.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>OGRANIČENJE OD ODGOVORNOSTI</h5>
                  <p>Korisnici koriste usluge informacionog društva isključivo na sopstvenu odgovornost, pri čemu izričito prihvataju da online zajednica ne može biti odgovorna za ponašanje drugih korisnika ili trećih lica, kao i da rizik od moguće štete u celosti snose ta lica. Za tačnost unetih podataka odgovaraju korisnici koji su ih uneli. </p>
                  <p>Online zajednica ne garantuje za tačnost, pouzdanost, kao ni za sam sadržaj postavljen od strane korisnika. Takođe ne inicira prenos elektronske poruke koju mu je predao korisnik usluga, ne vrši odabir podataka ili dokumenata koji se prenose, ne vrši izuzimanje ili izmenu podataka u sadržaju poruke ili dokumenta i ne vrši odabir primaoca prenosa.</p>
                  <p>Ova izjava o odgovornosti odnosi se na svu štetu (materijalnu i/ili nematerijalnu) ili povrede koje bi mogle nastati iz skrivenih nedostataka, grešaka, prekida, brisanja, kvara, kašnjenja u radu ili prenosu računarskih virusa, prekida u komunikacijama, krađe, uništenja ili neovlašćenog pristupa podacima, promene ili zloupotrebe podataka od strane trećih lica, raskida ugovora, ponašanja suprotnog Uslovima korišćenja, nemara i dr.
Kao pružalac usluge informacionog društva, online zajednica nije odgovorna za bilo koji sadržaj koji je postavilo drugo lice, korisnik, uključujući ali ne ograničavajući se na oglasnu poruku koju prenosi, jer niti inicira prenos, niti vrši odabir sadržaja koji se prenose, niti je izuzela ili izmenila podatke u sadržaju koji se prenosi, niti je odabrala primaoca prenosa, odnosno primaoca sadržaja.</p>
                  <p>Takođe ne garantuje za ponašanje trećih lica, ni svojih korisnika. Ne pruža nikakve garancije da će korisnik koji je oglasio dobro ili uslugu biti kontaktiran povodom tog oglasa od strane trećih lica (odnosno da će zaključiti posao koji je predmet oglasa), kao ni da će dostupne informacije sadržati tačne i istinite podatke.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>REŠAVANJE SPOROVA</h5>
                  <p>Na sve što nije regulisano ovim Uslovima korišćenja primenjuju se važeći propisi Republike Srbije.</p>
                  <p>Na sve sporove do kojih može doći između online organizacije, odnosno društva i korisnika primenjuju se važeći propisi Republike Srbije. Postoji pre svega obaveza rešavanja spora mirnim putem, a ukoliko se u tome ne uspe nadležan je za rešavanje spora Privredni sud u Novom Sadu za pravna, odnosno Osnovni sud u Novom Sadu za fizička lica. </p>
                  <p>Na sve sporove do kojih može doći između korisnika pri korišćenju usluga online zajednice BABE ŽABE primenjuju se ista pravila, bilo da je u pitanju spor između dva fizička lica, odnosno između fizičkog i pravnog lica. Pružalac usluge ni na koji način ne posreduje niti učestvuje u bilo kom sporu koji može nastati između korisnika, osim ako zakonom nije vezana kao nužni suparničar.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}>IZMENE I DOPUNE</h5>
                  <p>Online zajednica ima pravo da u svako doba izmeni ili dopuni ove Uslove korišćenja, tako što će izmene i dopune u prečišćenom tekstu objaviti na Internet prezentaciji i poslati e-mail svim registrovanim korisnicima, najmanje osam dana pre dana početka njihove primene.
Takođe ima pravo da u svako doba izmeni ili dopuni ove Uslove korišćenja, tako što će izmene i dopune u prečišćenom tekstu objaviti na internet stranici i poslati e-mail svim registrovanim korisnicima, najmanje osam dana pre dana početka njihove primene. Ukoliko već registrovani korisnik u navedenom roku ne da izričit odgovor (klikom na link za prihvatanje uslova korišćenja), smatraće se da je saglasan sa novim Uslovima korišćenja. Ukoliko već registrovan korisnik izričito (klikom na link za neprihvatanje uslova korišćenja) ne prihvati ove uslove korišćenja, smatraće se da mu je prestao korisnički status, da se gase sva dosadašnja prava i obaveze, te se ugovorni odnos prekida po prethodnim uslovima korišćenja. Stupanjem novih korisničkih uslova na snagu, ne diraju se stečena prava korisnika.</p>
                  <p>Ovi uslovi korišćenja počinju da se primenjuju osam dana od dana njihovog objavljivanja na internet stranici.</p>
                </div>
                <div>
                  <h5 style={{ marginTop: 20, marginBottom: 20 }}></h5>
                  <p>U Novom Sadu 2022. godine.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </MainLayout>
  );
}

export default Terms;
