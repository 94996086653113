import { runInAction, makeAutoObservable } from 'mobx';

import axios from '../axios-main';

class Auth {
  isAuthenticated = false;
  token = null;
  user = null;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (val) => {
    this.loading = val;
  }

  login = async (username, password) => {
    try {
      const body = {
        email: username,
        password: password,
      }

      const response = await axios.post('/auth/login', body);

      this.saveLogin(response.data.token, response.data.user);

      runInAction(() => {
        this.token = response.data.token;
        this.user = response.data.user;
        this.isAuthenticated = !!response.data.token;
        this.loading = false;
      });
    } catch (err) {
      console.log(err);
      runInAction(() => {
        this.token = null;
        this.user = null;
        this.loading = false;
      });
    }
  }

  register = async (user, img = null) => {
    try {
      const body = new FormData();

      for (const key in user) {
        body.append(key, user[key]);
      }
      body.append('image', img);

      const response = await axios.post('/auth/register', body);

      this.saveLogin(response.data.token, response.data.user);

      runInAction(() => {
        this.token = response.data.token;
        this.user = response.data.user;
        this.isAuthenticated = !!response.data.token;
        this.loading = false;
      });
    } catch (err) {
      console.log(err);

      runInAction(() => {
        this.token = null;
        this.user = null;
        this.loading = false;
      });
    }
  }

  logout = () => {
    this.token = null;
    this.user = null;
    this.loading = false;
    this.isAuthenticated = false;
  }

  saveLogin = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  }

  autoLogin = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    this.token = token;
    this.user = user;
    this.isAuthenticated = !!token;
  }
}

export default Auth;
