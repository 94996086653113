import { runInAction, makeAutoObservable } from 'mobx';

import axios from '../axios-main';

class Category {
  categories = [];
  types = [];

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (val) => {
    this.loading = val;
  }

  load = async () => {
    try {
      const response = await axios.get('/categories');

      runInAction(() => {
        this.categories = response.data.categories;
      });
    } catch (err) {
      console.log(err);
      runInAction(() => {
        this.categories = [];
      });
    }
  }
}

export default Category;
