import React  from 'react';
import { Link } from 'react-router-dom';

const slugToType = (slug) => {
  if (slug === 'usluge') {
    return 'sale';
  }
  if (slug === 'renta') {
    return 'rent';
  }
  if (slug === 'pokloni') {
    return 'booking';
  }

  if (slug === 'razmena') {
    return 'discount';
  }
  
  return '';
}

const AdCardSmall = (props) => {
  return (
          <Link to={`/ads/${props.id}`} style={{ width: '100%' }}>
    <div className="product-card">
      <div className="product-media">
        <div className="product-img">
          <img src={props.img} alt="product" />
        </div>
        <div className="product-type">
          <span className={`flat-badge ${slugToType(props.typeSlug)}`}>{props.type}</span>
        </div>
        <ul className="product-action">
        </ul>
      </div>
      <div className="product-content">
        <ol className="breadcrumb product-category">
          <li><i className="fas fa-tags"></i></li>
          <li className="breadcrumb-item"><a href="#">{props.category}</a></li>
          <li className="breadcrumb-item active" aria-current="page">{props.subcategory}</li>
        </ol>
        <h5 className="product-title">
          <Link to={`/ads/${props.id}`}>{props.title}</Link>
        </h5>
        <div className="product-meta">
          {props.place && <span><i className="fas fa-map-marker-alt"></i>{props.place}</span>}
          {/*<span><i className="fas fa-clock"></i>30 min ago</span>*/}
        </div>
        <div className="product-info">
          <h5 className="product-price">{props.price} RSD<span>/{props.priceType}</span></h5>
          <div className="product-btn">
          </div>
        </div>
      </div>
    </div>
    </Link>
  );
}

export default AdCardSmall;
