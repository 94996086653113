import React from 'react';

const AdHeader = (props) => {
  return (
    <div className="row">
      <div className="col-md-6 col-lg-6">
        <div className="common-card price" style={{ padding: 10 }}>
          <h3>{props.price} RSD<span>/{props.priceType}</span></h3>
          <i className="fas fa-tag"></i>
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <button type="button" className="common-card number" data-toggle="modal" data-target="#number" style={{ padding: 10 }}>
          <h3>{props.phone}<span>&nbsp;</span></h3>
          <i className="fas fa-phone"></i>
        </button>
      </div>
    </div>
  );
}

export default AdHeader;
