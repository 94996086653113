import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { withStore } from './store';
import Home from './pages/Home/Home';
import Logout from './pages/Auth/Logout/Logout';
import Auth from './pages/Auth/Auth';
import Profile from './pages/Profile/Profile';
import Ad from './pages/Ad/Ad';
import Categories from './pages/Categories/Categories';
import Search from './pages/Search/Search';
import Terms from './pages/Terms/Terms';
import AboutUs from './pages/AboutUs/AboutUs';
import EditAd from './pages/Profile/EditAd/EditAd';
import Help from './pages/Help/Help';

const App = (props) => {
  const { auth } = props;

  useEffect(() => {
    auth.autoLogin();
  }, [auth]);

  const routes = [
    {
      path: '/',
      component: <Home />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/auth/Logout',
      component: <Logout />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/auth/*',
      component: <Auth />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/profile/*',
      component: <Profile />,
      guard: {
        role: [ 'user' ]
      },
    },
    {
      path: '/ads/:id',
      component: <Ad />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/categories/:slug',
      component: <Categories />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/search',
      component: <Search />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/terms',
      component: <Terms />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/about_us',
      component: <AboutUs />,
      guard: {
        role: [ 'guest' ]
      },
    },
    {
      path: '/help',
      component: <Help />,
      guard: {
        role: [ 'guest' ]
      },
    },
  ];

  const routesComp = routes.filter((el) => {
    /*
    if (!this.props.isAuth && el.guard.role.includes('user')) {
      return false;
    }
    if (this.props.isAuth && !el.guard.role.includes('user') && !el.guard.role.includes('admin')) {
      return false;
    }
    */
    return true;
  }).map((el) => {
    return (
      <Route
        key={el.path}
        path={el.path}
        element={el.component} />
    );
  });

  return (
    <Routes>
      {routesComp}
    </Routes>
  );
}

export default withStore(['auth'])(App);
