import React from 'react';
import { Link } from 'react-router-dom';

const Menu = (props) => {
  const links = [
    /*
    {
      id: '',
      url: '/profile',
      label: 'Profil',
    },
    */
    {
      id: 'ads',
      url: '/profile/ads',
      label: 'Moji oglasi',
    },
    {
      id: 'new_ad',
      url: '/profile/new_ad',
      label: 'Novi oglas',
    },
    {
      id: 'settings',
      url: '/profile/settings',
      label: 'Izmena podataka',
    },
    /*
    {
      id: 'messagees',
      url: '/profile/messages',
      label: 'Poruke',
    },
    */
    /*
    {
      id: 'notifications',
      url: '/profile/notifications',
      label: 'Obaveštenja',
    },
    */
    {
      id: 'logout',
      url: '/auth/logout',
      label: 'Logout',
    },
  ];

  const menuItems = links.map((el) => {
    // <Link to={el.url} className="active">{el.label}</Liink>
    return (
      <li key={el.id}>
        <Link to={el.url}>{el.label}</Link>
      </li>
    );
  });

  return (
    <div class="dash-menu-list">
      <ul>
        {menuItems}
      </ul>
    </div>
  );
}

export default Menu;
